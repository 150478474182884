import React, { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const { currentUser } = useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  console.log(currentUser);

  const dofunc = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const credentials = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );

      // Re-authenticate the user
      await reauthenticateWithCredential(currentUser, credentials);

      // Check if the new password is strong enough
      if (newPassword.length < 6) {
        throw new Error(
          "Password is too weak. It should be at least 6 characters long."
        );
      }

      // Update the password
      await updatePassword(currentUser, newPassword);

      const userRef = doc(db, "users", currentUser.uid);

      // Update the document
      await updateDoc(userRef, { passwordChangeRequired: false });
      alert("Password has been reset successfully!");
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Error during password reset:", error);

      if (error.code === "auth/weak-password") {
        alert("Password is too weak. Please choose a stronger password.");
      } else if (error.code === "auth/wrong-password") {
        alert("Please make sure your previous password entered is right");
      }
      setCurrentPassword("");
      setNewPassword("");
      setIsLoading(false);
    }
  };

  return (
    <div className="section-padding">
      <section className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <form className="contact-form me-lg-5 pe-lg-3" onSubmit={dofunc}>
                <h2>Please create a new Password</h2>

                <div className="form-floating">
                  <input
                    type="password"
                    name="name"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="form-control"
                    placeholder="Full name"
                    required
                  />
                  <label htmlFor="name">Enter previous Password</label>
                </div>

                <div className="form-floating my-4">
                  <input
                    type="password"
                    name="email"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    // pattern="[^ @]*@[^ @]*"
                    className="form-control"
                    placeholder="Email address"
                    required
                  />
                  <label htmlFor="email">Enter new Password</label>
                </div>
                {isLoading && <span>Loading...</span>}

                <div className="col-lg-5 col-6">
                  <button type="submit" className="form-control">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <h1>hello</h1>

        <button onClick={dofunc}>press</button>
      </div> */}
    </div>
  );
};

export default PasswordReset;
