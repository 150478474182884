import { Link } from "react-router-dom";
import { useRef } from "react";
import { useInView } from "framer-motion";

const ReadMore = ({ pushTo }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  return (
    <Link to={pushTo}>
      <div
        ref={ref}
        className={
          isInView
            ? `hover-card hover-card-visible`
            : `hover-card hover-card-hidden`
        }
      >
        Read More
      </div>
    </Link>
  );
};

export default ReadMore;

// ref={ref}
// style={{
//   transform: isInView ? "none" : "translateX(-200px)",
//   opacity: isInView ? 1 : 0,
//   transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//   background: "white",
// }}
