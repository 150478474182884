import React, { useState, useEffect } from "react";
import { storage } from "../firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import { Carousel } from "react-bootstrap";
import Img1 from "../images/Members/active-member_page-0001.jpg";
import Img2 from "../images/Members/active-member_page-0002.jpg";
import Img3 from "../images/Members/active-member_page-0003.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Members = () => {
  //
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [pdfUrl, setPdfUrl] = useState(null);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // useEffect(() => {
  //   // Get reference to the PDF file
  //   const pathReference = ref(
  //     storage,
  //     "https://firebasestorage.googleapis.com/v0/b/community-aab58.appspot.com/o/Active%20member%20list.pdf"
  //   );

  //   // Get the download URL of the PDF file
  //   getDownloadURL(pathReference)
  //     .then((url) => {
  //       const iframe = document.getElementById("pdfViewer");
  //       iframe.src =
  //         url + //"#toolbar=0";
  //         setPdfUrl(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting download URL:", error);
  //     });
  // }, []);
  return (
    <div>
      <header class="site-header section-padding ">
        <div class="container">
          <div class="row">
            <div class="col">
              <h1 className="navy-blue">
                आम्हाला <span className="text-primary"> शिवप्रेरणाचे</span>{" "}
                सदस्य व्हायला आवडेल
              </h1>
            </div>
          </div>
        </div>
      </header>

      <section class="team section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="mb-5">
                संस्थापक <span>सदस्य</span>
                {/* व संस्थेचे <span>आधारस्तंभ</span> */}
              </h2>
            </div>

            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. शिवाजी महादेव राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. रामचंद्र नारायण राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. दिनकर दौलत राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. दाजी रामचंद्र राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. अत्रिनाथ सूर्याजी राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. बाळकृष्ण बाबुराव राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">कै.श्री. रामचंद्र बापूराव राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-4 col-12">
              <div class="team-thumb d-flex align-items-center">
                <div class="team-info">
                  <h5 class="mb-0">श्री. विनायक गोपाळ राणे</h5>
                  {/* <strong class="text-muted">Product, VP</strong> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding container mx-auto d-flex flex-column align-items-center">
        <div class="col">
          <h2 class="mb-4">
            संस्थेचे आजीव सभासदांची <span>यादी</span>
          </h2>

          {/* </div> */}
        </div>

        <div className="scroll-box d-flex flex-column align-items-center">
          <LazyLoadImage
            className="d-block col-12 col-sm-8"
            src={Img1} // replace with your image source
            alt="First slide"
          />
          <div className="mb-3"> Page 1 of 3</div>
          <LazyLoadImage
            className="d-block col-12 col-sm-8"
            src={Img2} // replace with your image source
            alt="First slide"
          />
          <div className="mb-3"> Page 2 of 3</div>
          <LazyLoadImage
            className="d-block col-12 col-sm-8"
            src={Img3} // replace with your image source
            alt="First slide"
          />
          <div className="mb-3"> Page 3 of 3</div>
        </div>
      </section>
    </div>
  );
};

export default Members;
