import React from "react";
import { useState, useContext, useEffect } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      // Update profile
      await updateProfile(res.user, {
        displayName: name,
      });

      // Create user on firestore
      await setDoc(doc(db, "users", res.user.uid), {
        uid: res.user.uid,
        name,
        email,
        member: false,
      });

      alert("Account created successfully !");
      // Navigate to the home page or any other route
      navigate("/");
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section class="sign-in-form section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto col-12 mt-5">
            <h1 class="hero-title text-center mb-5">Sign Up</h1>

            {/* <div class="social-login d-flex flex-column w-50 m-auto">
              <a href="#" class="btn custom-btn social-btn mb-4">
                <i class="bi bi-google me-3"></i>
                Continue with Google
              </a>

              <a href="#" class="btn custom-btn social-btn">
                <i class="bi bi-facebook me-3"></i>
                Continue with Facebook
              </a>
            </div>

            <div class="div-separator w-50 m-auto my-5">
              <span>or</span>
            </div> */}

            <div class="row">
              <div class="col-lg-8 col-11 mx-auto">
                <form onSubmit={handleSubmit} method="post">
                  <div class="form-floating">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="form-control"
                      placeholder="Full Name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />

                    <label for="Name">Name</label>
                  </div>
                  <div class="form-floating my-4">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      pattern="[^ @]*@[^ @]*"
                      class="form-control"
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <label for="email">Email address</label>
                  </div>

                  <div class="form-floating my-4">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      pattern="{6,10}"
                      class="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />

                    <label for="password">Password</label>

                    <p class="text-center">
                      * shall include 0-9 a-z A-Z in 4 to 10 characters
                    </p>
                  </div>

                  {/* <div class="form-floating">
                    <input
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                      pattern="[0-9a-zA-Z]{4,10}"
                      class="form-control"
                      placeholder="Password"
                      required
                    />

                    <label for="confirm_password">Password Confirmation</label>
                  </div> */}

                  <button
                    type="submit"
                    className={`btn custom-btn form-control mt-4 mb-3 ${
                      loading ? "loading" : ""
                    }`}
                    disabled={loading} // Disable button when loading
                  >
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    ) : (
                      "Create Account"
                    )}
                  </button>

                  <p class="text-center">
                    Already have an account? Please{" "}
                    <a href="/signin">Sign In</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
