import React, { useState } from "react";
import "../../assets/Festivals.css";
import { ShivpreranaImages, TraiVarhsikImages } from "../../constants/Images";
import SectionReveal from "../../components/SectionReveal";

const eventDetails = [
  {
    name: "राखण आणणे",
    src: "https://cdn.giftstoindia24x7.com/a21/144541.jpg?imgeng=w_800",
    description: `भाद्रपद कृष्ण द्वादशी या तिथीला मानकरी आणि गावकरी श्री देव रामेश्वराला गाऱ्हाणे घालून ५ किमी अंतरावर कांद्ळगावची वाडी असलेल्या महान या गावी गांगेश्वर मंदिरात पायी चालत जातात. दुपारी महाप्रसाद होतो. गांगेश्वराचा कौल घेऊन रामेश्वर आणि गांगेश्वर यांचा अवसर काढला (अंगात येणे) जातो. संपूर्ण गाव आणि भक्तगणांना वर्षभर पुरेल असं आत्मिक बळ निर्माण करणं म्हणजेच राखण आणणं. एकदा राखण आणली की भजन किर्तन बंद केली जातात. टाळ मृदूंग वाजवला जातो.`,
  },
  {
    name: "दसरोत्सव",
    src: "https://cdn.giftstoindia24x7.com/a21/144541.jpg?imgeng=w_800",
    description: `अश्विन शुद्ध सप्तमीला रामेश्वर मंदिरात दसरोत्सवाला सुरुवात होते. साधारण दुपारी ४च्या सुमारास रामेश्वर, गांगेश्वर, सातेरी, रवळनाथ, कमरादेवी यांची तरंगे वारेसुत्रासह (ज्यांच्या अंगात येतं ते), सहवारेसुत्रासह तमाम रयत घेऊन सातेरी मंदिराकडे प्रस्थान करतात. मंदिराशेजारी असलेल्या आपट्याच्या झाडाची विधीवत पूजा करून शिवलग्नाचा सोहळा संपन्न होतो. आणि सोनं लुटलं जातं. यानंतर सर्व वारेसुत्र तरंगांसह सातेरी मंदिरात येतात आणि तिथून पुढे रामेश्वर मंदिराच्या समोर असलेल्या प्रसिद्ध मांडावर स्थिरावतात आणि रयतेला आशिर्वाद देतात. त्यांच्या अडीअडचणींचे निवारण करतात. दुसऱ्या दिवशी देव स्वारी सातेरी मंदिरात येते. व वारेसुत्र आपले तरंग ठेवून प्रस्थान ठेवतात.`,
  },
  {
    name: "हरिनाम सप्ताह",
    src: "https://cdn.giftstoindia24x7.com/a21/144541.jpg?imgeng=w_800",
    description: `तुळशीविवाहाला सातवी रात्र येईल या पद्धतीने, तिथीनुसार सांगायचं झालं तर कार्तिक पंचमी ते कार्तिक द्वादशीपर्यत अखंड हरिनाम सप्ताह चालतो. सात रात्र चालणाऱ्या या सप्ताहाचं यजमानपद आपल्याकडे अर्थात राणेंकडे तीन रात्री, तसेच परबांकडे तीन रात्री आणि एक रात्र साळकरवाडी यांचेकडे असतं. (हरिनाम सप्ताहात जास्तीत जास्त सहभाग नोंदवावा)`,
  },
  {
    name: "दहिकाला",
    src: "https://cdn.giftstoindia24x7.com/a21/144541.jpg?imgeng=w_800",
    description: `प्रत्येकाच्या अगदी आवडीचा हा उत्सव आहे. रात्रीच्या वेळी रामेश्वर वारेसुत्रे, पालखी आणि मानकरी सातेरी देवीचं भेट देऊन मांडातील गादीवर आरुढ होतात. नि भाविकांना आशिर्वाद देतात. यानंतर दशावतार नाटक सुरु होते. यावेळी रात्रीभर धम्माल मस्ती, खरेदी, खेळ सुरु असतात. सकाळी रामेश्वर वारेसुत्रासमोर दशावतार नाटकातील कृष्णाच्या पेहरावात असलेला कलाकार दह्याचं गाडगं फोडतो. आणि आरती होऊन दहिकाल्याची सांगता होते.`,
  },
];

const events = [
  {
    name: "गुढीपाडवा",
    date: "(चैत्र शुद्ध प्रतिपदा)",
  },
  {
    name: "रामनवमी",
    date: "(चैत्र शुद्ध नवमी)",
  },
  {
    name: "राखण आणणे",
    date: "(श्रावण द्वादशी)",
  },
  {
    name: "घटस्थापना",
    date: "(अश्विन शुद्ध प्रतिपदा)",
  },
  {
    name: "दसरोत्सव",
    date: "(अश्विन शुद्ध दशमी)",
  },
  {
    name: "हरिनाम सप्ताह",
    date: "(कार्तिक शुद्ध पंचमी ते द्वादशी अखंड हरिनाम सप्ताह)",
  },
  {
    name: "त्रिपुरारी पोर्णिमा",
    date: "(कार्तिक शुद्ध पोर्णिमा)",
  },
  {
    name: "दहिकाला",
    date: "(मार्गशिर्ष शुद्ध द्वादशी)",
  },
  {
    name: "कलशारोहण",
    date: "(पौष कृष्ण पक्ष दशमी)",
  },
  {
    name: "महाशिवरात्री",
    date: "(माघ कृष्ण चतुर्दशी)",
  },
  {
    name: "होलिकोत्सव",
    date: "(फाल्गुण शुद्ध पोर्णिमा)",
  },
];

const EventList = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <div>
      <div className="section-padding container">
        <div class="col-12 text-center">
          <h2 class="mt-5">
            श्री देव रामेश्वर मंदिरात तिथीप्रमाणे संपन्न होणारे वार्षिक उत्सव
          </h2>
        </div>

        <div className="row" id="accordionGeneral">
          <div className="col-lg-7 mx-auto">
            <ul className="timeline">
              {events.map((event, index) => (
                <li
                  key={index}
                  className="timeline-item bg-white rounded ml-3 p-4 shadow"
                >
                  <div className="timeline-arrow"></div>

                  <div className="d-flex align-items-center gap-3">
                    <h3 className="mb-0">{event.name}</h3>

                    <button
                      onClick={() => toggleAccordion(index)}
                      style={{ cursor: "pointer" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordionGeneral${index}`}
                      aria-expanded="true"
                      aria-controls={`accordionGeneral${index}`}
                    ></button>
                  </div>
                  <div
                    className={
                      "collapse" + (activeIndex === index ? " show" : "")
                    }
                  >
                    <div className="card card-body">{event.date}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <section className=" container">
        <h2 className="text-center mb-5">
          काही प्रमुख <span>कार्यक्रम</span> (विस्तृत)
        </h2>

        {eventDetails.map((event_, index) => {
          return (
            <SectionReveal cssClasses="mb-4">
              <h4 className="text-center text-primary">{event_.name}</h4>
              <p>{event_.description}</p>
            </SectionReveal>
          );
        })}
      </section>

      {/* <section class="about section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h2 class="mb-5">
                काही प्रमुख कार्यक्रम <span>(विस्तृत)</span>
              </h2>
            </div>

            <div class="col-lg-2 col-12 mt-auto mb-auto">
              <ul
                class="nav nav-pills mb-5 mx-auto justify-content-center align-items-center"
                id="pills-tab"
                role="tablist"
              >
                {eventDetails.map((eventDetail, index) => (
                  <li key={index} className="nav-item" role="presentation">
                    <button
                      className="nav-link "
                      id={`pills-${eventDetail.name}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${eventDetail.name}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${eventDetail.name}`}
                      aria-selected="true"
                    >
                      {eventDetail.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div class="col-lg-10 col-12">
              <div class="tab-content mt-2" id="pills-tabContent">
                {eventDetails.map((eventDetail, index) => (
                  <div
                    class="tab-pane fade show active"
                    id={`pills-${eventDetail.name}`}
                    role="tabpanel"
                    aria-labelledby={`pills-${eventDetail.name}-tab`}
                  >
                    <div class="row">
                      <div class="col-lg-7 col-12">
                        <img src={eventDetail.src} class="img-fluid" alt="" />
                      </div>

                      <div class="col-lg-5 col-12">
                        <div class="d-flex flex-column h-100 ms-lg-4 mt-lg-0 mt-5">
                          <p>{eventDetail.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div class="my-5">
        <div class="container mt-5 ">
          <h2 class="text-center">त्रेवार्षिक उत्सव.</h2>
          <ul class="list-group mt-3">
            <p className="list-group-item block-orange">
              • प्रत्येक तीन वर्षांनी श्री देव रामेश्वर यांची सिंधुदुर्ग भेट
            </p>
            <p class="list-group-item block-orange">
              • प्रत्येक तीन वर्षांनी डाळपस्वारी – आपल्याकडे देव येतात तो खाटा
              दिवस (वाराचा दिवस) वेशीवर कोंबडं सोडावं लागतं. देव रामेश्वर यावेळी
              ब्राह्मणदेवाकडेच थांबतात. तर गांगेश्वर वेस फिरून येतात
            </p>
            <p class="list-group-item block-orange">
              • प्रत्येक तीन वर्षांनी तुळजाभवानी आईचा कार्यक्रम – दोन दिवस,
              वाड्यामधून दिंडी काढली जाते, लघुरुद्र, अभिषेक केला जातो, भवीनीच्या
              मंदिरात महाप्रसाद ठेवण्यात येतो
            </p>
          </ul>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-6">
            <img src={TraiVarhsikImages[0].file} class="img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            <img src={TraiVarhsikImages[1].file} class="img-fluid" alt="" />
          </div>
        </div>

        <div className="row mt-4 align-items-center">
          <div className="col-md-6">
            <img src={TraiVarhsikImages[2].file} class="img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            <img src={TraiVarhsikImages[3].file} class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventList;
