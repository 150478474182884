import React from "react";
import SectionReveal from "../components/SectionReveal";
import { ShivpreranaImages } from "../constants/Images";

const Shivprerana = () => {
  return (
    <div className="">
      <SectionReveal cssClasses="site-header section-padding-img site-header-image">
        <div class="container mt-5">
          <div class="row mt-5">
            <div class="col-lg-6 mt-5 col-12 header-info">
              <h2>
                <span className="navy-blue">
                  श्री शिवप्रेरणा राणेवाडा (कांदळगाव) रहिवाशी सेवा संघ, मुंबई
                </span>
                {/* <span class="text-dark">gaon</span> */}
              </h2>
              <h5>(नोंदणी क्र. १९९८ जी.बी.बी.एस्. डी. ६११ दि. ६ मे १९९८)</h5>
            </div>
          </div>
        </div>

        <img
          src={ShivpreranaImages[0].file}
          class="header-image img-fluid"
          alt=""
        />
      </SectionReveal>

      <section class=" section-padding">
        <div class="container">
          <div class="row">
            <div class="col">
              {/* <h4>About Shivprerana</h4> */}
              <p className=" ">
                कांदळगांवांतील "राणे मूळपुरूषाचे" ॠण अमान्य करील अशी एकही
                व्यक्ति त्या गावांत आढळणार नाही. कारण, कांदळगांवातील राणे
                मूळपुरूषाने ३५० वर्षापूर्वी त्या गांवात केलेले सत्कार्य ही
                वस्तूस्थिती, नव्हे ज्वलंत इतिहास आहे. साधारणतः १४ पिढयापूर्वी
                (३५० वर्षापूर्वी) दादजी (पटेल) राणे हा राजपुरूष आपल्या १५०
                सैनिकासह जलमार्गे शेलटी या ठिकाणी उतरला. अल्पावधीतच, कांदळगांवचे
                मूळचे रहिवाशी (पूर्व सत्ताधारी) यांच्या पूर्व संमतीने व गांवातील
                सर्व रहिवाश्यांच्या सहकार्याने तत्कालीन व्यवस्थेनुसार कार्यरत
                असलेल्या तेथील मुख्य सरदाराच्या मनमानी कारभाराच्या जाचातून
                सर्वांची यशस्वीरित्या मुक्तता करून राजसत्ता संपादन केली.
                राजसत्ताधारी, पूर्वसत्ताधारी व बारा बलुतेदार (सेवक) यांच्या
                संपूर्ण सहकार्याने गांवरहाटी (गांवची व्यवस्था) व देव रहाटी
                (देवस्थानाचा कारभार) या बाबत नीतीनियम करून त्याची न्याय्य
                पध्दतीने व सर्वांना सम्मानाची वागणूक देऊन यशस्वीरीत्या
                अंमलबजावणी केली व एक न्यायी राजसत्ताधारी म्हणून समाज्यामध्ये
                विशिष्ट स्थान प्राप्त केले. हा एक प्रेरणादायक इतिहास आहे.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class=" section-padding">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-5">
              {/* <h4>Subtitle 2</h4> */}
              <p className=" ">
                <img
                  className="d-block w-100 "
                  src={ShivpreranaImages[1].file} // replace with your image source
                  alt="First slide"
                  style={{ height: "500px", objectFit: "cover" }}
                />
              </p>
            </div>
            <div class="col-md-7 mt-4">
              <p>
                कालानुरूप व भारताच्या स्वातंत्र्य प्राप्तीनंतर अशा व्यवस्थामध्ये
                थोडेफार बदल झाले असले तरी ज्या ज्या ठिकाणी शहान्नवकुळी मराठयांचे
                राज्य होते त्या त्या प्रांतात / गांवात, खास करून, कोकणांत गांव
                रहाटी, देवरहाटी या रूढी, परंपरा व चालीरिती आजही अस्तित्वात आहेत
                हे कोणीही नाकारूं शकणार नाही, कारण ते सत्य आहे.
              </p>
              <p>
                वर नमूद केलेले सर्व जरी पूर्ण सत्य असलं तरी, जसं "मान मागून मिळत
                नसतो, तर तो कमवावा लागतो", "स्वस्थ बसून पोट भरत नाही, तर
                त्यासाठी प्रयत्न करावे लागतात" त्याच न्यायाने केवळ पूर्वजांच्या
                प्रतिष्ठेवर किंवा त्यांनी कमवलेल्या शिदोरीवर फार काळ जगता येत
                नाही. साहजिकच, आपल्यापैकी प्रत्येकाने आत्मपरिक्षण करण्याची वेळ
                आलेली आहे की, आपल्या मूळपुरूषाचा वरसा सांगण्यासारखे आपले विचार व
                आचरण आहे काय ? नसल्यास, आपण आपल्या विचारात व आचरणात योग्य ते
                चांगले बदल करण्यास तयार आहोत काय ?
              </p>

              <p>
                चांगले काय व वाईट काय याचा विचार करण्याची बुध्दी परमेश्वराने
                फक्त मनुष्य प्राण्याला दिलेली आहे परंतु, त्या बुध्दीचा योग्य तो
                उपयोग करणे केवळ त्या त्या व्यक्तिच्या सद्सद्विवेक बुध्दीवर
                अवलंबून आहे. आज जगात सर्वत्र जीवनांची मूल्ये बदलत असून माणूस
                पैसा हे साधन न मानता अंतीम साध्य समजून त्याभोवती घोटाळत आहे.
                त्याचा दुष्परिणाम म्हणजे नीती-नियमांचे उल्लंघन, व्यसन,
                भ्रष्टाचार, व्यभिचार, नात्यागोत्यांचा व सामाजिक बांधिलकीचा
                समाज्याला पडलेला विसर म्हणजेच, संपूर्ण सृष्टीचे अस्तित्व
                नाकारणे, म्हणजेच सर्वनाशः
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section class=" section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <p className=" ">
                अशा या गोंधळाच्या परिस्थितीत कांदळगांवची ग्रामदेवता, स्वयंभू
                श्री देव रामेश्वराच्या प्रेरणेने, राणे मूळपुरूषाचा प्रेरणादायी
                इतिहास व सामाजिक बांधिलकीचे व्रत घेऊन 'श्री शिव प्रेरणा राणेवाडा
                कांदळगांव, रहिवाशी सेवा संघ मुंबई ही संस्था १ ऑक्टोबर १९८९ साली
                कौटुंबीक संस्था म्हणून उदयाला आली व ६ मे १९९८ रोजी नोंदणीकृत
                होऊन एक सार्वजनिक विश्वस्तव्यवस्था म्हणून कार्यरत आहे.
                आतापर्यंतच्या कार्यकालावधीत, कौटुंबीक मेळावे, स्नेहसम्मेलन, सभा,
                सांस्कृतिक, शैक्षणिक, सामाजिक व धार्मिक उपक्रमांद्वारे तसेच
                पारदर्शक आर्थिक व्यवहार व इतर कार्याद्वारे आपल्या पूर्वजांचा
                वारसा जपायचा आपणा सर्वांच्या सहकार्याने आम्ही यशस्वी प्रयत्न
                करीत आहोत. याच योजना व उपक्रम मोठ्या प्रमाणावर यशस्वी करण्यासाठी
                वरिल ध्येयाने झपाटलेले आपल्या संस्थेचे आम्ही २१ विश्वस्त "संकल्प
                २००९" घेऊन व दर तीन वर्षांनी खेडेगांवात आपल्या जन्म गांवी
                होणा-या स्नेहसम्मेलनाचा योग साधून आपणापुढे काही उपक्रम विचारार्थ
                ठेवत आहोत. आतापर्यंत आपण दिलेला भरघोस पाठींबा व उत्स्फूर्त
                प्रतिसादाने लाभलेल्या विश्वासाच्या जोरावर हा धाडसी संकल्प आपणा
                समोर ठेवत आहोत. त्याचे यशापयश केवळ आपणां सर्वांच्या सक्रीय सहभाग
                व आर्थिक व इतर योगदानावर अवलंबून आहे याची आम्हाला जाणीव आहे.
              </p>
            </div>
            <div class="col-md-5 mt-4">
              <img
                className="d-block w-100"
                src={ShivpreranaImages[2].file}
                alt="First slide"
              />
            </div>
          </div>
        </div>
      </section> */}

      <section class=" section-padding">
        <div class="container">
          <div class="row">
            <div class="col">
              {/* <h3 className="text-center">Subtitle 4</h3> */}
              <p className=" ">
                आशा आहे, या उपक्रमाला सक्रिय पाठींबा देऊन आपण सर्वचजणग
                मूळपुरूषाचा वारसा सांगायला योग्य आहोत हे कांदळगांवातील व इतर
                सर्वांना पटवून देऊया.
              </p>
              <p>
                संस्थेची ध्येय धोरणे कायमस्वरूपी अविरत राबविण्यासाठी "संकल्प
                २०२४" या योजने अंतर्गत रूपये १,००,००,०००/- (रूपये एक कोटी कमीत
                कमी) जमा करण्याचे उद्दिष्ट असून राणेवाडयातील प्रत्येक
                उत्पन्नधारक रहिवाशी व्यक्तिने (कुटुंबाने नव्हे) आपल्या (पगार
                सेवानिवृत्तीवेतन, व्यवसाय व इतर) उत्पन्नातून कमीत कमी रूपये
                ५०००/- (पाच हजार) शक्यतो (क्रॉस) चेक रूपाने "श्री शिवप्रेरणा
                राणेवाडा (कांदळगांव) रहिवाशी सेवा संघ मुंबई" या नांवाने
                संस्थेपाशी त्वरीत जमा करून त्याची रीतसर पावती घ्यावी.
                माहेरवासिनी व इतर हितचिंतकांनी स्वेच्छेने यथाशक्ती आपले आर्थिक व
                इतर योगदान द्यावे असे आपुलकीचे आवाहन कार्यकारिणी व एकवीस
                विश्वस्तांच्या वतीने आपणा सर्वांस करण्यात येत आहे. आतापर्यंत
                आपणाकडून लाभलेले एकूणच सहकार्य, जिव्हाळा, प्रेम व त्यातून
                लाभलेला विश्वास, स्वयंभू श्रीदेव रामेश्वराची प्रेरणा, कुलदेवतेचे
                कृपा आशिर्वाद व कार्यकर्त्यांचे प्रामाणिक प्रयत्न व पारदर्शक
                कार्य या सर्व घटनांमुळे आम्ही प्रभावित झालो असून आपले सहकार्य व
                सक्रिय योगदानावर आमचा पूर्ण विश्वास आहे.
              </p>

              <p>
                चला तर, आपण सर्वजण लहान-मोठा, श्रीमंत गरिब असे सर्व भेदभाव व
                मतभेद विसरून या पवित्र कार्याला वाहून घेऊया! धन्यवाद !
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={ShivpreranaImages[3].file} class="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <img src={ShivpreranaImages[4].file} class="img-fluid" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img src={ShivpreranaImages[2].file} class="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <img src={ShivpreranaImages[8].file} class="img-fluid" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img src={ShivpreranaImages[7].file} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Shivprerana;
