import React, { createContext, useEffect, useState } from "react";
import { auth } from "../firebaseConfig";
import { onAuthStateChanged, getIdTokenResult } from "firebase/auth";
import { fetchUserDetails } from "../firebaseUtils/fetchUserDetails";
import { Navigate, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserDetails(user.uid).then((data) => {
          console.log(data);
          const claimsData = {
            member: data?.member,
            passwordChangeRequired: data?.passwordChangeRequired,
          };
          setClaims(claimsData);

          if (data.passwordChangeRequired === true) {
            console.log("hye00");
            navigate("/password-reset");
          }
        });
        setCurrentUser(user);
      } else {
        setCurrentUser(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, claims }}>
      {children}
    </AuthContext.Provider>
  );
};
