import React from "react";
import { Carousel } from "react-bootstrap";
import { imageData } from "../constants/Images";
import { headerImages } from "../constants/Images";
import Soldier from "../images/Home/Ranes/soldier.png";
import SectionReveal from "../components/SectionReveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import ReadMore from "../components/ReadMore";

const Home = () => {
  return (
    <>
      {/* Header */}
      <SectionReveal cssClasses="slick-slideshow">
        <div class="slick-custom ">
          <Carousel fade>
            <Carousel.Item>
              <LazyLoadImage
                className="d-block cover-img"
                src={headerImages[0].file} // replace with your image source
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <LazyLoadImage
                className="d-block cover-img"
                src={headerImages[1].file} // replace with your image source
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
          <div class="slick-bottom">
            <div class="container">
              <div class="row">
                <div class="col">
                  <h2 class="slick-title">
                    श्री शिवप्रेरणा राणेवाडा (कांदळगाव) रहिवाशी सेवा संघ, मुंबई
                  </h2>

                  <p class="lead text-white mt-lg-3 mb-lg-5">
                    {/* श्री शिवप्रेरणा राणेवाडा (कांदळगाव) रहिवाशी सेवा संघ, मुंबई */}
                  </p>

                  {/* <a href="about.html" class="btn custom-btn">
                    Learn more about us
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionReveal>

      {/* Rane history */}
      <SectionReveal cssClasses="section-padding beige readmore-hover">
        <div class="container">
          <div class="row align-items-center ">
            <div
              class="col-md-7 bg-image"
              style={{ "--backgroundImage": `url(${Soldier})` }}
            >
              <h4>
                कांदळगावच्या
                <span className="text-primary"> राणेंचा इतिहास</span>{" "}
              </h4>
              <p
                style={{
                  fontWeight: 600,

                  // position: "relative"
                }}
              >
                कांदळगावंचे आम्ही राणे साक्ष इतिहासाची, समृद्ध परंपरेची
                <br />
                मुळ स्थान – उदयपूर, राजस्थान
                <br />
                कांदळगावातील आगमन – १४ पिढ्यांपूर्वी
                <br />
                कांदळगावातील मुळ पुरुष – दादजी (पटेल) राणे
                <br />
                आगमन स्थान – कांदळगाव सीमा – शेलटी
                <br />
                स्वपराक्रमाने कांदळगावची राजसत्ता प्राप्त
                <br />
                गांवातील कारभारासाठी नितीनियम गांव रहाटी व देव रहाटीची निर्मिती
                पालन
                <br />
                गोत्र – शांडिल्य, कुळ – कळंब
                <br />
                राजधर्माचे तंतोतंत पालन – गावांत शांतात, एकोपा, बंधूभाव, परस्पर
                सहकार्याचे तथा सौहार्दाचे वातावरण
                <ReadMore pushTo={"/kandalgaon/history-ranes"} />
              </p>
              {/* <Link to="/kandalgaon/history-ranes">Read More</Link> */}
            </div>
            <div class="col-md-5 mt-4">
              <Carousel fade>
                <Carousel.Item>
                  <LazyLoadImage
                    className="d-block w-100"
                    src="https://res.cloudinary.com/dpjkhc3rk/image/upload/q_auto:eco/f_auto/v1714210174/Website%20images/History%20of%20kandalgaon/%E0%A4%95%E0%A4%BE%E0%A4%82%E0%A4%A6%E0%A4%B3%E0%A4%97%E0%A4%BE%E0%A4%B5_%E0%A4%B8%E0%A5%80%E0%A4%AE%E0%A5%87%E0%A4%B5%E0%A4%B0%E0%A5%80%E0%A4%B2_%E0%A4%B6%E0%A5%87%E0%A4%B2%E0%A4%9F%E0%A5%80_%E0%A4%AF%E0%A5%87%E0%A4%A5%E0%A5%80%E0%A4%B2_%E0%A4%96%E0%A4%BE%E0%A4%A1%E0%A5%80%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A6%E0%A5%87%E0%A4%B6_gn6atj.jpg"
                    alt="First slide"
                  />
                  <Carousel.Caption
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <span className="font-caption">
                      शेलटी येथील राणे मुळपुरूष आगमन ठिकाण
                    </span>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <LazyLoadImage
                    className="d-block w-100"
                    src="https://res.cloudinary.com/dpjkhc3rk/image/upload/q_auto:eco/f_auto/v1714210176/Website%20images/History%20of%20kandalgaon/%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87_%E0%A4%AE%E0%A5%81%E0%A4%B3%E0%A4%AA%E0%A5%81%E0%A4%B0%E0%A5%81%E0%A4%B7_%E0%A4%A6%E0%A4%BE%E0%A4%A6%E0%A4%9C%E0%A5%80_%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87_%E0%A4%86%E0%A4%AA%E0%A4%B2%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%B8%E0%A5%88%E0%A4%A8%E0%A4%BF%E0%A4%95%E0%A4%BE%E0%A4%82%E0%A4%B8%E0%A4%B9_%E0%A4%AF%E0%A4%BE%E0%A4%9A%E0%A4%A0%E0%A4%BF%E0%A4%95%E0%A4%BE%E0%A4%A3%E0%A5%80_%E0%A4%AA%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%A4%E0%A5%82%E0%A4%A8_%E0%A4%89%E0%A4%A4%E0%A4%B0%E0%A4%B2%E0%A5%87_nfwpno.jpg" // replace with your image source
                    alt="Second slide"
                  />

                  <Carousel.Caption
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <span className="font-caption">
                      राणे मुळपुरुष दादजी राणे आपल्या सैनिकांसह याचठिकाणी
                      पाडावातून उतरले
                    </span>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </SectionReveal>

      {/* sanstha */}
      <SectionReveal cssClasses="section-padding">
        <div class="container mt-5 ">
          <div className="col my-5 ">
            <h2 class="text-center">
              अशी आहे आमची संस्था -{" "}
              <span className="text-primary">शिवप्रेरणा</span>
            </h2>

            <div className="pb-2">
              <p className="" style={{ textIndent: "30px" }}>
                {"    "}काही वर्षांपूर्वी सिंधुदुर्ग जिल्ह्यातील मालवण तालुक्यात
                दुर्गम ठिकाणी असलेल्या कांदळगाव याठिकाणी तेथील स्थानिक तसेच
                चाकरमानी तरुण तुर्क मंडळींनी एकत्र येऊन आपल्या वाडीसाठी,
                गावासाठी तसेच समाजाच्या उत्कर्षासाठी एकत्र यायच ठरवलं. गावातील
                प्रत्येक ग्रामस्थाला शिक्षण, क्रीडा व आरोग्यासंबंधित सर्वोत्तम
                सुविधा उपलब्ध करण्यासाठी एका सामाजिक संस्थेची स्थापना करण्याचा
                निर्णय घेण्यात आला. याच संस्थेच्या माध्यमातून अन्न,वस्त्र,
                निवारा, पाणी व्यवस्थापन आदी प्राथमिक सुविधांकडे देखील लक्ष
                देण्याबाबत ठराव करण्यात आला. आपल्या उत्पन्नातील काही वाटा हा
                आपल्या जन्मभूमीसाठी खर्च करत असताना गावातील इतर तरुणांना आर्थिक
                विकासासाठी सहकार्य, मार्गदर्शन आणि मदत करण्याचे प्राथमिक ध्येय
                नजरेसमोर ठेवत श्री शिवप्रेरणा राणेवाडा (कांदळगाव) रहिवाशी सेवा
                संघ, मुंबई ही संस्था कार्य करत आहे.
                <ReadMore pushTo={"/shivprerana"} />
              </p>

              {/* <div className="px-4 hover-card" style={{ textAlign: "right" }}>
                <Link to="/shivprerana">Read More</Link>
              </div> */}
            </div>
          </div>

          <ul class="list-group mt-3">
            <p className="list-group-item block-orange">
              • स्वयंभू श्री देव रामेश्वराकडून प्रेरणा लाभलेली.
            </p>
            <p class="list-group-item block-orange">
              • सामाजिक बांधिलकीचे जाण व भान ठेवत स्वतःच्या कमाईतील काही अंश
              समर्पित करणारी गावातील रहिवाशांच्या शैक्षणिक, सामाजिक, आर्थिक,
              वैद्यकीय, क्रिडा-कला व सांस्कृतिक उन्नतीसाठी खर्च करणारी
            </p>
            <p class="list-group-item block-orange">
              • कोणत्याही राजाश्रय, सरकारी आश्रयाशिवाय कार्यरत असलेली.
            </p>
            <p class="list-group-item block-orange">
              • कोणत्या गटातटांच्या किंवा राजकीय पुढाऱ्याच्या हस्तक्षेपाला वाव
              नसलेली.
            </p>
            <p class="list-group-item block-orange">
              • श्रीमंत - गरीब किंवा जातीपातीमध्ये भेदभाव न करणारी
            </p>
            <p class="list-group-item block-orange  ">
              • कोणत्याही पदाची, मोठेपणाची किंवा प्रसिद्धीच्या हव्यासाला बळी न
              पडता केवळ सामाजिक ऋण म्हणून निरपेक्ष बुद्धीने संस्थेच्या प्रगतीला
              शक्य असेल तसा हातभार लावणाऱ्या सभासद कार्यकर्ते व
              हितचिंतकाचे सहाय्य लाभलेली.
            </p>
          </ul>
        </div>
      </SectionReveal>

      {/* kandalgaon */}
      <SectionReveal cssClasses="section-padding orange">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-lg-6 col-12">
              <div class="px-5 py-5 py-lg-0">
                <h2 class="mb-4">
                  <span>कांदळ</span>गांव
                </h2>

                <p class="lead mb-3">
                  कांदळगाव ग्रामदेवता - स्वयंभू श्री देव रामेश्वर कांदळगांव
                  येथील सुप्रसिध्द श्री देव रामेश्वर मंदिराबाबत आतापर्यंत
                  प्रकाशित झालेल्या माहितीवर दृष्टिक्षेप टाकल्यास असे जाणवते की
                  ती माहिती परिपूर्ण नाही. पुढील पिढ्यांना या इतिहासाची संपूर्ण
                  माहिती व्हावी या एकाच उद्देशाने खालील लिखाणामागील प्रामाणिक
                  हेतूची वाचकांनी नोंद घ्यावी.
                </p>

                <p class="lead ">
                  उपलब्ध माहितीनुसार श्री देव रामेश्वराचे प्रकटीकरण शिवपूर्व
                  काळचे असावे. ही बाब छत्रपती श्री शिवाजी महाराजांना दृष्टांत
                  झाल्यानंतर स्वयंभू पाषाणरुपी पिंडीवर महाराजांनी घुमटीचे
                  बांधकाम केले यावरुन स्पष्ट होते. या संदर्भातील कथा फारच
                  मनोरंजक आहेत. आज ज्या ठिकाणी श्री देव रामेश्वराचे मंदिर आहे,
                  त्या ठिकाणी श्री शंकराची पिंडी प्रकट होण्यापूर्वी घनदाट जंगल
                  होते…
                  <ReadMore pushTo={"/kandalgaon"} />
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-12">
              <Carousel
              // className="col-md-7 border-radius "
              >
                {imageData.map((eachImage) => {
                  return (
                    <Carousel.Item key={eachImage.caption}>
                      <LazyLoadImage
                        className="d-block w-100 image-carousel-size "
                        src={eachImage.file} // replace with your image source
                        // loading="lazy"
                      />
                      <Carousel.Caption
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        <span className="font-caption">
                          {eachImage.caption}
                        </span>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </SectionReveal>

      {/* <section className="section-padding">
        <div className="container">
          <div className="row">
            <div class="col-12 text-center">
              <h2 class="mb-5">
                Summary of Kandal<span>gaon</span>
              </h2>
            </div>
          </div>
          <p>
            कांदळगांव येथील सुप्रसिध्द श्री देव रामेश्वर मंदिराबाबत आतापर्यंत
            प्रकाशित झालेल्या माहितीवर दृष्टिक्षेप टाकल्यास असे जाणवते की ती
            माहिती परिपूर्ण नाही. पुढील पिढ्यांना या इतिहासाची सम्प माहिती
            व्हावी या एकाच उद्देशाने खालील लिखाणामागील प्रामाणिक हेतूची वाचकांनी
            नोंद घ्यावी. उपलब्ध माहितीनुसार श्री देव रामेश्वराचे प्रकटीकरण
            शिवपूर्व काळचे असावे. ही बाब छत्रपती श्री. शिवाजी महाराजांना
            दृष्टांत झाल्यानंतर स्वयंभू पाषाणरुपी पिंडीवर महाराजांनी घुमटीचे
            बांधकाम केले यावरुन स्पष्ट होते.
          </p>

          <div className="d-flex justify-content-center ">
            <Carousel className="col-md-7 border-radius ">
              {imageData.map((eachImage) => {
                return (
                  <Carousel.Item id={eachImage.caption}>
                    <img
                      className="d-block w-100 image-carousel-size "
                      src={eachImage.file} // replace with your image source
                      alt="First slide"
                    />
                    <Carousel.Caption
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                      }}
                    >
                      <span className="font-caption">{eachImage.caption}</span>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section> */}

      {/* <section class="about section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h2 class="mb-5">
                Summary of Kandal<span>gaon</span>
              </h2>
            </div>

            <div class="col-lg-2 col-12 mt-auto mb-auto">
              <ul
                class="nav nav-pills mb-5 mx-auto justify-content-center align-items-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Introduction
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-youtube-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-youtube"
                    type="button"
                    role="tab"
                    aria-controls="pills-youtube"
                    aria-selected="true"
                  >
                    How we work?
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-skill-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-skill"
                    type="button"
                    role="tab"
                    aria-controls="pills-skill"
                    aria-selected="false"
                  >
                    Capabilites
                  </button>
                </li>
              </ul>
            </div>

            <div class="col-lg-10 col-12">
              <div class="tab-content mt-2" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div class="row">
                    <div class="col-lg-7 col-12">
                      <img
                        src="images/pim-chu-z6NZ76_UTDI-unsplash.jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </div>

                    <div class="col-lg-5 col-12">
                      <div class="d-flex flex-column h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 class="mb-3">
                          Good <span>Design</span> <br />
                          Ideas for
                          <span>your</span> fashion
                        </h4>

                        <p>
                          Little Fashion templates comes with
                          <a href="sign-in.html">sign in</a> /
                          <a href="sign-up.html">sign up</a> pages, product
                          listing / product detail, about, FAQs, and contact
                          page.
                        </p>

                        <p>
                          Since this HTML template is based on Boostrap 5 CSS
                          library, you can feel free to add more components as
                          you need.
                        </p>

                        <div class="mt-2 mt-lg-auto">
                          <a href="about.html" class="custom-link mb-2">
                            Learn more about us
                            <i class="bi-arrow-right ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-youtube"
                  role="tabpanel"
                  aria-labelledby="pills-youtube-tab"
                >
                  <div class="row">
                    <div class="col-lg-7 col-12">
                      <div class="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube-nocookie.com/embed/f_7JqPDWhfw?controls=0"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>

                    <div class="col-lg-5 col-12">
                      <div class="d-flex flex-column h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 class="mb-3">Life at Studio</h4>

                        <p>
                          Over three years in business, We’ve had the chance to
                          work on a variety of projects, with companies
                        </p>

                        <p>Custom work is branding, web design, UI/UX design</p>

                        <div class="mt-2 mt-lg-auto">
                          <a href="contact.html" class="custom-link mb-2">
                            Work with us
                            <i class="bi-arrow-right ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-skill"
                  role="tabpanel"
                  aria-labelledby="pills-skill-tab"
                >
                  <div class="row">
                    <div class="col-lg-7 col-12">
                      <img
                        src="images/cody-lannom-G95AReIh_Ko-unsplash.jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </div>

                    <div class="col-lg-5 col-12">
                      <div class="d-flex flex-column h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 class="mb-3">What can help you?</h4>

                        <p>
                          Over three years in business, We’ve had the chance on
                          projects
                        </p>

                        <div class="skill-thumb mt-3">
                          <strong>Branding</strong>
                          <span class="float-end">90%</span>
                          <div class="progress">
                            <div
                              class="progress-bar progress-bar-primary"
                              role="progressbar"
                              aria-valuenow="90"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: "90%" }}
                            ></div>
                          </div>

                          <strong>Design & Stragety</strong>
                          <span class="float-end">70%</span>
                          <div class="progress">
                            <div
                              class="progress-bar progress-bar-primary"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: "70%" }}
                            ></div>
                          </div>

                          <strong>Online Platform</strong>
                          <span class="float-end">80%</span>
                          <div class="progress">
                            <div
                              class="progress-bar progress-bar-primary"
                              role="progressbar"
                              aria-valuenow="80"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: "80%" }}
                            ></div>
                          </div>
                        </div>

                        <div class="mt-2 mt-lg-auto">
                          <a href="products.html" class="custom-link mb-2">
                            Explore products
                            <i class="bi-arrow-right ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="featured-product section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h2 class="mb-5">Featured Products</h2>
            </div>

            <div class="col-lg-4 col-12 mb-3">
              <div class="product-thumb">
                <a href="product-detail.html">
                  <img
                    src="images/product/evan-mcdougall-qnh1odlqOmk-unsplash.jpeg"
                    class="img-fluid product-image"
                    alt=""
                  />
                </a>

                <div class="product-top d-flex">
                  <span class="product-alert me-auto">New Arrival</span>

                  <a href="#" class="bi-heart-fill product-icon"></a>
                </div>

                <div class="product-info d-flex">
                  <div>
                    <h5 class="product-title mb-0">
                      <a href="product-detail.html" class="product-title-link">
                        Tree pot
                      </a>
                    </h5>

                    <p class="product-p">Original package design from house</p>
                  </div>

                  <small class="product-price text-muted ms-auto mt-auto mb-5">
                    $25
                  </small>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 mb-3">
              <div class="product-thumb">
                <a href="product-detail.html">
                  <img
                    src="images/product/jordan-nix-CkCUvwMXAac-unsplash.jpeg"
                    class="img-fluid product-image"
                    alt=""
                  />
                </a>

                <div class="product-top d-flex">
                  <span class="product-alert">Low Price</span>

                  <a href="#" class="bi-heart-fill product-icon ms-auto"></a>
                </div>

                <div class="product-info d-flex">
                  <div>
                    <h5 class="product-title mb-0">
                      <a href="product-detail.html" class="product-title-link">
                        Fashion Set
                      </a>
                    </h5>

                    <p class="product-p">Costume Package</p>
                  </div>

                  <small class="product-price text-muted ms-auto mt-auto mb-5">
                    $35
                  </small>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12">
              <div class="product-thumb">
                <a href="product-detail.html">
                  <img
                    src="images/product/nature-zen-3Dn1BZZv3m8-unsplash.jpeg"
                    class="img-fluid product-image"
                    alt=""
                  />
                </a>

                <div class="product-top d-flex">
                  <a href="#" class="bi-heart-fill product-icon ms-auto"></a>
                </div>

                <div class="product-info d-flex">
                  <div>
                    <h5 class="product-title mb-0">
                      <a href="product-detail.html" class="product-title-link">
                        Juice Drinks
                      </a>
                    </h5>

                    <p class="product-p">Nature made another world</p>
                  </div>

                  <small class="product-price text-muted ms-auto mt-auto mb-5">
                    $45
                  </small>
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <a href="products.html" class="view-all">
                View All Products
              </a>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;

{
  /* 
      <section class="about section-padding">
        <div className="container">
          <iframe
            width="1300"
            height="400"
            id="gmap_canvas"
            src="https://maps.google.com/maps?hl=en&amp;q=%20IC%20Colony+(IC%20Colony)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
          <script
            type="text/javascript"
            src="https://embedmaps.com/google-maps-authorization/script.js?id=496912832c29850647afa52541869ac616bc19fc"
          ></script>
        </div>
      </section> */
}
