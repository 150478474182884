import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { VisitCounterContext } from "../context/VisitCounterProvider";

const Footer = () => {
  const { visits } = useContext(VisitCounterContext);

  const subsRef = useRef(null);

  useEffect(() => {
    const odometer = new window.Odometer({
      el: subsRef.current,
      value: visits,
    });
    odometer.update(visits);
  }, [visits]);

  return (
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-10 me-auto mb-4">
            <Link className="" to="/">
              <h4 class="text-white mb-3">
                <span className="text-orange">शिव</span>प्रेरणा
              </h4>
              <p className="text-white  copyright-text">
                {/* (नोंदणी क्र. १९९८ जी.बी.बी.एस्. डी. ६११ दि. ६ मे १९९८) */}
              </p>
            </Link>

            <p class="copyright-text">
              <b>कार्यालयाचा पत्ता :</b> स्व श्री. शिवाजी म. राणे, प्लॉट नं. १७,
              हॅप्पी होम्स को. ऑप. हाउसिंग सोसायटी जयवंत सावंत मार्ग, दहिसर
              (पश्चिम), मुंबई ४०० ०६८.
            </p>
            <p class="copyright-text text-muted">
              <b>Mail :</b>{" "}
              <a
                className="copyright-text"
                href="mailto:shivprerana.rane@gmail.com"
              >
                shivprerana.rane@gmail.com
              </a>
            </p>
            <p class="copyright-text">
              Made with ❤️
              <a target="_blank"> in India</a>
              <h5 className="text-primary mt-2">
                👁️
                <div ref={subsRef} className="subs ">
                  0
                </div>
              </h5>
            </p>
          </div>

          <div class="col-lg-5 col-8">
            <h5 class="text-white mb-3">Sitemap</h5>

            <ul class="footer-menu d-flex flex-wrap">
              <li class="footer-menu-item">
                <Link to="/" class="footer-menu-link">
                  Home
                </Link>
              </li>

              <li class="footer-menu-item">
                <Link to="/kandalgaon" class="footer-menu-link">
                  Kandalgaon
                </Link>
              </li>

              <li class="footer-menu-item">
                <Link to="/kandalgaon" class="footer-menu-link">
                  Shivprerana
                </Link>
              </li>

              <Link to="/members" class="footer-menu-link">
                Members
              </Link>

              <li class="footer-menu-item">
                <Link to="/events" class="footer-menu-link">
                  Events
                </Link>
              </li>

              <li class="footer-menu-item">
                <Link to="/contact" class="footer-menu-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-4">
            <ul class="social-icon">
              <li>
                <a
                  target="blank"
                  href="https://youtube.com/@RaneShivprerana?si=eX5HgYYtPM4jD3dY"
                  class="social-icon-link bi-youtube"
                  // style={{ fontSize: "2rem" }}
                ></a>
              </li>

              <li>
                <a
                  href="#"
                  class="social-icon-link bi-whatsapp"
                  // style={{ fontSize: "1.5rem" }}
                ></a>
              </li>

              <li>
                <a
                  href="#"
                  class="social-icon-link bi-instagram"
                  // style={{ fontSize: "2rem" }}
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
