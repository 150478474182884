import React from "react";
import { imageData } from "../../constants/Images";
import SectionReveal from "../../components/SectionReveal";
import { Link } from "react-router-dom";

const Kandalgaon = () => {
  return (
    <div>
      <SectionReveal cssClasses="site-header section-padding-img site-header-image">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 header-info">
              <h1>
                <span class="text-primary">कांदळगाव </span>
                <span class="navy-blue">
                  ग्रामदेवता <span class="text-primary"> -</span>स्वयंभू{" "}
                  <span class="text-primary">श्री देव रामेश्वर</span>
                </span>
              </h1>
            </div>
          </div>
        </div>

        <Link to="https://earth.google.com/web/@16.11621696,73.4815866,26.61903792a,784.83356521d,35y,168.63885052h,60.00008244t,-0r/data=CjkaNxIxCiUweDNiYzAxZGJjNTZkODI0ZmI6MHg2ODVhMjRiYzgzMzA1ODQ4KghSYW5ld2FkYRgCIAE6AwoBMA">
          <img
            title="View on Google Earth"
            src={
              "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714210607/Website%20images/Kandalgaon/kandalgaon_topxwpid3.png"
            }
            class="header-image img-fluid"
            alt=""
          />
        </Link>
      </SectionReveal>

      <div class="container my-5">
        <div class="row">
          <div class="col-md-4">
            <img src={imageData[1].file} class="img-fluid mb-3 " />
          </div>
          <div class="col-md-8">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-8">
                <p>
                  कांदळगांव येथील सुप्रसिध्द श्री देव रामेश्वर मंदिराबाबत
                  आतापर्यंत प्रकाशित झालेल्या माहितीवर दृष्टिक्षेप टाकल्यास असे
                  जाणवते की ती माहिती परिपूर्ण नाही. पुढील पिढ्यांना या
                  इतिहासाची संपूर्ण माहिती व्हावी या एकाच उद्देशाने खालील
                  लिखाणामागील प्रामाणिक हेतूची वाचकांनी नोंद घ्यावी.
                </p>
                <p>
                  उपलब्ध माहितीनुसार श्री देव रामेश्वराचे प्रकटीकरण शिवपूर्व
                  काळचे असावे. ही बाब छत्रपती श्री शिवाजी महाराजांना दृष्टांत
                  झाल्यानंतर स्वयंभू पाषाणरुपी पिंडीवर महाराजांनी घुमटीचे
                  बांधकाम केले यावरुन स्पष्ट होते.
                </p>
              </div>
              <img
                src="https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301725/Website%20images/Kandalgaon/%E0%A4%95%E0%A5%81%E0%A4%B2%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%BE%E0%A4%AE%E0%A4%BF%E0%A4%A8%E0%A5%80_%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A4%E0%A5%81%E0%A4%B3%E0%A4%9C%E0%A4%AD%E0%A4%B5%E0%A4%BE%E0%A4%A8%E0%A5%80_%E0%A4%86%E0%A4%88_%E0%A4%AE%E0%A4%82%E0%A4%A6%E0%A4%BF%E0%A4%B0_yz73sd.jpg"
                class="img-fluid img-thumbnail "
                alt="Temple Image"
                style={{ width: "35vh" }}
              />
            </div>
          </div>
        </div>
      </div>

      <section class=" section-padding">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-5">
              <p className=" ">
                <img
                  className="d-block w-100 "
                  src={imageData[2].file} // replace with your image source
                  alt="First slide"
                  style={{ height: "500px", objectFit: "cover" }}
                />
              </p>
            </div>
            <div class="col-md-7 mt-4">
              <p>
                या संदर्भातील कथा फारच मनोरंजक आहेत. आज ज्या ठिकाणी श्री देव
                रामेश्वराचे मंदिर आहे, त्या ठिकाणी श्री शंकराची पिंडी प्रकट
                होण्यापूर्वी घनदाट जंगल होते. 'राय' (राई) होती, शेजारीच असलेल्या
                परिसरात एका ब्राह्मणाचे वास्तव्य होते. त्याच्यापाशी एक दुभती गाय
                होती. ही गाय त्या ब्राह्मणाला किंवा वासराला दूध देत नसे. त्या
                गायीला चरण्यासाठी सोडले असता क्षणार्धात ती गाय त्या
                ब्राह्मणाच्या दृष्टीआड होत असे. गाय चरुन घरी आल्यावर मात्र
                तिच्या ओटीमध्ये आढळणारे दूध संपलेले असायचे. गायीला मारझोड करुन
                सुध्दा ती गाय ब्राह्मणाच्या दृष्टीआड होण्यापूर्वी पान्हा सोडत
                नसे. ब्राह्मणाला या गोष्टीचे आश्चर्य वाटू लागले आणि त्याचबरोबर
                रागही येऊ लागला. गाय चरण्यापूर्वी दुधाने ओथंबलेली ओटी गाय चरुन
                आल्यानंतर कशी काय रिकामी होते या गोष्टीचा बोध घेण्याचा त्याने
                निश्चय केला. गायीला चरण्याला सोडल्यानंतर तिचा पाठलाग करण्याचे
                निश्चित केले. दुसऱ्या दिवशी गायीला चरायला सोडले आणि तिच्यावर
                पाळत ठेवली. गायीला गोठ्यातून सोडताच क्षणार्धात ती रायमध्ये शिरली
                व अदृश्य झाली. ब्राह्मणाने रायमध्ये शोध घेतला असता त्याला असे
                आढळले की एका झुडपाशी येऊन तिने आपला पान्हा त्या ठिकाणी रिता
                केला. ब्राह्मणाला या गोष्टीचा अचंबा वाटला परंतु प्रत्यक्ष मात्र
                त्या ठिकाणी त्याला ते झुडूप व मातीशिवाय काहीच आढळले नाही.
                कुतुहलापोटी त्या ब्राह्मणाने त्या झुडुपाची साफसफाई करुन ज्या
                ठिकाणी गाईने पान्हा सोडला त्या ठिकाणी कुदळीने खोदण्यास सुरुवात
                केली. ठराविक अंतरापर्यंत खोदकाम करताच त्याच्या कुदळीचा घाव
                पाषाणाला लागला व त्या ठिकाणी पाषाणाचा तुकडा निघून त्यातून
                रक्ताच्या चिळकांड्या उडाल्या. ब्राह्मण भयभीत झाला व त्याने
                घडलेली हकीगत आपल्या कुटुंबियांना व गावातील लोकांना कथन केली.
                साहजिकच सर्वांनी मिळून त्या ठिकाणचे परीक्षण केले असता. त्या
                जमिनीत पाषाणरुपी स्वयंभू श्री शंकराची पिंडी निघाली. भयभीत
                झालेल्या त्या ब्रह्मणाने मनोभावे त्या पाषाणापाशी क्षमायाचना करुन
                त्या पिंडीला दुधाने आंघोळ घालून नियमितपणे पूजाअर्चा करण्याचा
                आपला संकल्प जाहीर केला.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class=" section-padding">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-7">
              <p className=" ">
                काही कालावधीनंतर मोगलांच्या उत्तर हिंदूस्थानातील अतिक्रमणाला व
                हिंदू धर्मियांच्या होणाऱ्या छळाला यशस्वीपणे तोंड देण्यासाठी
                हिंदूस्थानच्या दक्षिणेकडील व कोकणातील कडेकपारीतील भूमीची श्री
                छत्रपती शिवाजी महाराजांनी सुरक्षित ठिकाणे म्हणून निवड केली.
                आरमारी सामर्थ्यावर भर देण्याचे निश्चित करुन मालवण येथील चारही
                बाजूनी समुद्राने वेढलेल्या बेटावर व सध्या सिंधुदूर्ग किल्ला
                असलेल्या कुरटे बेटाची निवड केली. या किल्ल्याच्या तटबंदीचे काम
                चालू केले असता त्या ठिकाणी दगडांचा थर स्थिर होत नसल्याचे शिवाजी
                महाराजांना आढळून आले. किल्ला तर उभारायचा आहे परंतु बांधकाम मात्र
                टिकत नसल्याचे स्पष्ट झाल्यानंतर छत्रपती शिवाजी महाराज चिंतित
                झाले. या अवस्थेत महाराज निंद्रिस्त असताना मध्यरात्री त्यांना
                दृष्टांत झाला. त्या दृष्टांतातील संकेतानुसार त्यांनी उन्हापावसात
                उघड्यावर असलेल्या पाषाणरुपी पिंडीवर सध्या श्री देव रामेश्वर
                मंदिर असलेल्या गाभाऱ्यातील छोटीशी घुमटी बांधली आणि काय चमत्कार!
                काही कालावधीतच अशक्यप्राय वाटणारे सिंधुदूर्ग किल्ल्याच्या
                उभारणीचे काम पूर्ण झाले.
              </p>
            </div>
            <div class="col-md-5 mt-4 mx-auto">
              <img
                className="d-block img-fluid"
                style={{ objectFit: "cover" }}
                src={imageData[13].file} // replace with your image source
              />
            </div>
          </div>
        </div>
      </section>

      <section class=" section-padding">
        <div class="container">
          <div class="row">
            <div class="col">
              <p className=" ">
                अशी देदीप्यमान ख्याती असलेला पाषाणरुपी स्वयंभू श्री देव रामेश्वर
                अनन्यभावे शरण जाणाऱ्या भक्तांच्या दुःखाचे निवारण करतो, याबाबत
                अनेक आख्यायिका असून मीदेखील त्याबाबत अनुभव घेतलेले आहेत. दसरा
                सणाच्या वेळी पाच दिवस साजरा होणाऱ्या सोहळ्याच्या वेळी कोकणापासून
                घाट-माथ्यापर्यंत या स्वयंभू श्री देव रामेश्वराचे भक्तगण तसेच
                त्या परिसरातील विविध देवस्थाने आपापल्या अडीअडचणींचे निवारण करुन
                घेतात. ही वस्तुस्थिती असून कोणतीही व्यक्ती त्या बाबतीत अनुभव घेऊ
                शकते. श्री देव रामेश्वर देवस्थान व त्यांचे परमभक्त छत्रपती श्री.
                शिवाजी महाराज यांच्यात परस्पर निर्माण झालेले नाते याचे एक उदाहरण
                म्हणजेच दर तीन वर्षांनी श्री देव रामेश्वर कांदळगावातील इतर
                ग्रामदेवतांच्या सहित व भक्तजनांना बरोबर घेऊन सिंधुदूर्ग
                किल्ल्यातील शिवाजी महाराजांच्या देवालयाला व त्या ठिकाणी
                प्रतिस्थापना केलेल्या देवतांची भेट घेण्यासाठी जातात. किल्ल्यात
                श्री देव रामेश्वराचा भव्य सत्कार होतो. याप्रसंगी एकमेकांना
                नजरांणा देऊन सन्मानित केले जाते. तसेच मालवण व सभोवतालच्या
                परिसरातील सारी जनता भक्तिभावाने या सोहळ्यात सहभागी होते. अशी आहे
                स्वयंभू श्रीदेव रामेश्वररुपी आमची ग्रामदेवता !
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={imageData[9].file} class="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <img src={imageData[5].file} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Kandalgaon;
