// import { Outlet, Navigate, useLocation } from "react-router-dom";
// import { AuthContext } from "./AuthContext";
// import { useContext, useState, useEffect } from "react";

// const PrivateRoute = () => {
//   const { currentUser, claims } = useContext(AuthContext);
//   const [isLoading, setIsLoading] = useState(true);
//   const location = useLocation();

//   useEffect(() => {
//     // Check if currentUser is fetched
//     if (currentUser !== undefined) {
//       setIsLoading(false);
//     }
//   }, [currentUser]);

//   if (isLoading) {
//     // If still loading, show a loading indicator or just return null
//     return null;
//   }

//   if (window.location.pathname === "/events") {
//     // Check if the user's role is allowed to access the "/events" route
//     if (claims !== "member") {
//       // Redirect to unauthorized page
//       return <Navigate to="/" />;
//     }
//   }

//   if (currentUser) {
//     return <Outlet />;
//   } else {
//     // Redirect to login page if currentUser is null
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }
// };

// export default PrivateRoute;

import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { useContext, useState, useEffect } from "react";

const PrivateRoute = () => {
  const { currentUser, claims } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if currentUser and claims are fetched
    if (currentUser !== null && claims !== null) {
      setIsLoading(false);
    }
  }, [currentUser, claims]);

  if (isLoading) {
    // Return a loading state or a skeleton component while data is being fetched
    return;
  }

  if (window.location.pathname === "/members") {
    // Check if the user's role is allowed to access the "/events" route
    if (currentUser) {
      if (!claims.member) {
        // Redirect to unauthorized page
        return <Navigate to="/" />;
      }
    } else {
      // Redirect to login page if currentUser is null
      return <Navigate to="/login" />;
    }
  }

  if (currentUser) {
    return <Outlet />;
  } else {
    // Redirect to login page if currentUser is null
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
