// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../firebaseConfig";

// export const fetchEventDetails = async () => {
//   try {
//     const eventsRef = collection(db, "events");
//     const querySnapshot = await getDocs(eventsRef);

//     const eventsData = [];
//     querySnapshot.forEach((doc) => {
//       eventsData.push({ id: doc.id, ...doc.data() });
//     });

//     return eventsData;
//   } catch (error) {
//     console.error("Error fetching event details:", error);
//     throw error; // Rethrow the error to handle it at the calling site
//   }
// };

import {
  orderBy,
  collection,
  query,
  getDocs,
  startAfter,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export const fetchEventDetails = async (pageSize, lastVisible) => {
  try {
    let eventsRef = collection(db, "events");
    const q = query(
      eventsRef,
      where("upcoming", "==", false),
      orderBy("date", "desc")
    );

    // If lastVisible is provided, start the query after the last document fetched
    if (lastVisible) {
      eventsRef = query(q, startAfter(lastVisible));
    }

    // Limit the number of documents returned to the specified pageSize
    eventsRef = query(q, limit(pageSize));

    const querySnapshot = await getDocs(eventsRef);

    const eventsData = [];
    querySnapshot.forEach((doc) => {
      eventsData.push({ id: doc.id, ...doc.data() });
    });

    console.log(eventsData);

    // Return both the fetched events data and the first and last visible documents for pagination
    return {
      eventsData,
      firstVisible: querySnapshot.docs[0],
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching event details:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
};
