import React from "react";
import { Link } from "react-router-dom";

const EventsCard = ({ event }) => {
  const date = event.dateText.split(" ");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  return (
    <div class="col-md-12 mb-4">
      <div class="card card-animation ">
        <div class="row no-gutters ">
          <div class="col-md-8">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 ">
                  <h6 class="mb-0 orange">{month}</h6>
                  <h2 class="mb-0  ">{day}</h2>
                  <h6 class="mb-0 orange">{year}</h6>
                </div>
                <div class="col-md-8">
                  <h6 class="card-text ">{event.dateText}</h6>

                  <Link to={`/event-info/${event.id}`}>
                    <h5 class="card-text highlighting navy-blue">
                      {event.title}
                    </h5>
                  </Link>
                  {/* <p class="card-text">
                    ગુજરાત વિદ્યાલય રસ્ટ (હિરાબાગ બેજરગંજી સભાગૃહ, પ્રેમાબાઈની
                    ચાળમાં, વિદ્યાલય માર્ગ, ઉસ્માનપુરા, અમદાવાદ
                  </p>
                  <p class="card-text">
                    વિદ્ય ગુજરાતી સમાજના માનાર્થે સન્માન તથા પુસ્તક વિમોચન
                    સમારંભ
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <img
              src={event.images[0].url}
              class="card-img card-img-custom img-fluid img-thumbnail"
              alt="Event Image"
              style={{ height: "300px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsCard;
