import React from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

const RightToLeft = ({ children, cssClasses }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }

    return () => {
      mainControls.stop();
    };
  }, [isInView, mainControls]);
  return (
    <React.Fragment>
      <motion.section
        ref={ref}
        class={cssClasses}
        variants={{
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              type: "spring",
              damping: 12,
              stiffness: 100,
              duration: 1,
              delay: 0.5,
            },
          },
          hidden: {
            opacity: 0,
            x: 20,
            transition: {
              type: "spring",
              damping: 12,
              stiffness: 100,
            },
          },
        }}
        initial="hidden"
        animate={mainControls}
        // transition={{ duration: 2, delay: 10 }}
      >
        {children}
      </motion.section>
    </React.Fragment>
  );
};

export default RightToLeft;
