import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { AuthContext } from "../context/AuthContext";
import { fetchUserDetails } from "../firebaseUtils/fetchUserDetails";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email + "@shivprerana.in",
        password
      );
      console.log(userCredentials.user.uid);
      const userId = userCredentials.user.uid;
      const userDoc = await fetchUserDetails(userId);
      console.log(userDoc);
      // console.log(userDoc.data().requiredPasswordChange);
      if (userDoc && userDoc.passwordChangeRequired) {
        navigate("/password-reset");
      } else {
        navigate("/");
      }
    } catch (err) {
      alert("Login falied- Invalid credentials !");
      //   var modalCheckbox = document.getElementById("my_modal_6");
      // Set the checkbox to checked to open the modal
      //   modalCheckbox.checked = true;
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const { currentUser, claims } = useContext(AuthContext);

  useEffect(() => {
    if (claims?.passwordChangeRequired) {
      navigate("/password-reset");
    } else if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <section class="sign-in-form section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto col-12 mt-5">
            <h1 class="hero-title text-center mb-5">Sign In</h1>

            <div class="row">
              <div class="col-lg-8 col-11 mx-auto">
                <form onSubmit={handleSubmit} role="form" method="post">
                  <div class="form-floating mb-4 p-0">
                    <input
                      // type="email"
                      name="email"
                      id="email"
                      class="form-control"
                      placeholder="Username"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <label for="email">Username</label>
                  </div>

                  <div class="form-floating p-0">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      class="form-control"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <label for="password">Password</label>
                  </div>

                  <button
                    type="submit"
                    className={`btn custom-btn form-control mt-4 mb-3 ${
                      loading ? "loading" : ""
                    }`}
                    disabled={loading} // Disable button when loading
                  >
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    ) : (
                      "Sign in"
                    )}
                  </button>

                  {/* <p class="text-center">
                    Don’t have an account? <a href="/signup">Create One</a>
                  </p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
