const PageNotFound = () => {
  return (
    <div className="container mt-5 section-padding">
      <div className="row">
        <div className="col-md-6 offset-md-3 text-center">
          <h1 className="display-4 mb-4">404 - Page Not Found</h1>
          <p className="lead">
            Sorry, the page you are looking for does not exist.
          </p>
          <p className="mb-5">
            Please check the URL or go back to the homepage.
          </p>
          <a href="/" className="btn social-btn ">
            Go to Homepage
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
