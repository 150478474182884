// import React, { useContext } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { AuthContext } from "../context/AuthContext";
// import Logo from "../images/logo2.jpg";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
// import NavDropdown from "react-bootstrap/NavDropdown";

// const CustomNavbar = () => {
//   const { currentUser } = useContext(AuthContext);
//   const location = useLocation();

//   const isActive = (path) => {
//     return location.pathname === path;
//   };

//   return (
//     <Navbar expand="lg" className="navbar navbar-expand-lg">
//       <div className="container">
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Link className="navbar-brand navy-blue" to="/">
//           <LazyLoadImage src={Logo} alt="GIF" className="logo" />
//           <strong>
//             <span className="">शिव</span>प्रेरणा
//           </strong>
//         </Link>

//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="mx-auto">
//             <NavDropdown title="कांदळगावचे राणे" id="kandalgaonDropdown">
//               <NavDropdown.Item as={Link} to="/kandalgaon/history-ranes">
//                 कांदळगावच्या राण्यांचा इतिहास
//               </NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/kandalgaon">
//                 कांदळगाव
//               </NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/kandalgaon/village-festival">
//                 कांदळगावातील उत्सव
//               </NavDropdown.Item>
//             </NavDropdown>

//             <Nav.Link
//               className={`nav-link ${isActive("/shivprerana") && "active"}`}
//               as={Link}
//               to="/shivprerana"
//             >
//               शिवप्रेरणा
//             </Nav.Link>
//             <Nav.Link
//               className={`nav-link ${isActive("/members") && "active"}`}
//               as={Link}
//               to="/members"
//             >
//               सभासद
//             </Nav.Link>
//             <Nav.Link
//               className={`nav-link ${isActive("/events") && "active"}`}
//               as={Link}
//               to="/events"
//             >
//               उपक्रम
//             </Nav.Link>
//             <Nav.Link
//               className={`nav-link ${isActive("/contact") && "active"}`}
//               as={Link}
//               to="/contact"
//             >
//               संपर्क
//             </Nav.Link>
//           </Nav>
//           <div className="d-none d-lg-block">
//             {currentUser ? (
//               <span>
//                 {currentUser.displayName}{" "}
//                 <Link
//                   to="/account-info"
//                   className="bi-person custom-icon me-3"
//                 ></Link>
//               </span>
//             ) : (
//               <Link to="/login" className="bi-person custom-icon me-3"></Link>
//             )}
//           </div>
//         </Navbar.Collapse>
//       </div>
//     </Navbar>
//   );
// };

// export default CustomNavbar;

import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Logo from "../images/logo2.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = () => {
  const { currentUser, claims } = useContext(AuthContext);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <Link className="navbar-brand navy-blue" to="/">
          <LazyLoadImage src={Logo} alt="GIF" className="logo" />
          <strong>
            <span className="">शिव</span>प्रेरणा
          </strong>
        </Link>

        <div className="d-lg-none">
          <Link to="/login" className="bi-person custom-icon me-3"></Link>
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${
                  isActive("/kandalgaon") && "active"
                }`}
                // to="/kandalgaon"
                id="kandalgaonDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                कांदळगावचे राणे
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="kandalgaonDropdown"
                style={{
                  background: "#f2f2da",
                }}
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="/kandalgaon/history-ranes"
                    // onClick={() => handleKandalgaonDropdown("a")}
                  >
                    कांदळगावच्या राणेंचा इतिहास
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/kandalgaon"
                    // onClick={() => handleKandalgaonDropdown("b")}
                  >
                    कांदळगाव
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/kandalgaon/village-festival"
                    // onClick={() => handleKandalgaonDropdown("c")}
                  >
                    कांदळगावातील उत्सव
                  </a>
                </li>
              </ul>
            </li>

            {/* <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/kandalgaon") && "active"}`}
                to="/kandalgaon"
              >
                Kandalgaon
              </Link>
            </li> */}

            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/shivprerana") && "active"}`}
                to="/shivprerana"
              >
                शिवप्रेरणा
              </Link>
            </li>

            {claims?.member && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${isActive("/members") && "active"}`}
                  to="/members"
                >
                  सभासद
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/events") && "active"}`}
                to="/events"
              >
                उपक्रम
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/contact") && "active"}`}
                to="/contact"
              >
                संपर्क
              </Link>
            </li>
          </ul>

          <div className="d-none d-lg-block">
            {currentUser ? (
              <span>
                {currentUser.displayName}{" "}
                <Link
                  to="/account-info"
                  // onClick={() => {
                  //   navigate("/account-info");
                  // }}
                  className="bi-person custom-icon me-3"
                ></Link>
              </span>
            ) : (
              <Link to="/login" className="bi-person custom-icon me-3"></Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
