// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvZ48lXybbQAAF1YMBpuRntW_QEmFBr5A",
  authDomain: "community-aab58.firebaseapp.com",
  projectId: "community-aab58",
  storageBucket: "community-aab58.appspot.com",
  messagingSenderId: "425243288815",
  appId: "1:425243288815:web:3f4fa74b25d35247f3a71a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const realtimeDB = getDatabase();
export const analytics = getAnalytics(app);
