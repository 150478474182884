import React from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

const SectionReveal = ({ children, cssClasses }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }

    return () => {
      mainControls.stop();
    };
  }, [isInView, mainControls]);
  return (
    <React.Fragment>
      <motion.section
        ref={ref}
        class={cssClasses}
        variants={{
          hidden: { opacity: 0, x: 0 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, delay: 0.5 }}
      >
        {children}
      </motion.section>
    </React.Fragment>
  );
};

export default SectionReveal;
