export const imageData = [
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714476912/Website%20images/Kandalgaon/कुलस्वामिनी_श्री_तुळजभवानी_आई_मंदिर_ojns03.jpg",
    caption: "कुलस्वामिनी श्री तुळजभवानी आई मंदिर",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301763/Website%20images/Kandalgaon/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5_%E0%A4%B0%E0%A4%BE%E0%A4%AE%E0%A5%87%E0%A4%B6%E0%A5%8D%E0%A4%B5%E0%A4%B0_%E0%A4%AE%E0%A4%82%E0%A4%A6%E0%A4%BF%E0%A4%B0_dtqbul.jpg",
    caption: "श्री देव रामेश्वर मंदिर",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301722/Website%20images/Kandalgaon/%E0%A4%93%E0%A4%9D%E0%A4%B0_%E0%A4%AF%E0%A5%87%E0%A4%A5%E0%A5%80%E0%A4%B2_%E0%A4%97%E0%A5%81%E0%A4%B9%E0%A5%87%E0%A4%A4%E0%A5%80%E0%A4%B2_%E0%A4%AC%E0%A5%8D%E0%A4%B0%E0%A4%B9%E0%A5%8D%E0%A4%AE%E0%A4%BE%E0%A4%A8%E0%A4%82%E0%A4%A6_%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%BE%E0%A4%AE%E0%A5%80%E0%A4%82%E0%A4%9A%E0%A5%87_%E0%A4%AE%E0%A4%A0._tt1t2r.jpg",
    caption: "ओझर येथील गुहेतील ब्रह्मानंद स्वामींचे मठ.",
  },

  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301731/Website%20images/Kandalgaon/%E0%A4%A6%E0%A4%B8%E0%A4%B0%E0%A4%BE_%E0%A4%AE%E0%A4%B9%E0%A5%8B%E0%A4%A4%E0%A5%8D%E0%A4%B8%E0%A4%B5%E0%A4%BE%E0%A4%9A%E0%A5%87_%E0%A4%B5%E0%A5%87%E0%A4%B3%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5_%E0%A4%B2%E0%A4%97%E0%A5%8D%E0%A4%A8_%E0%A4%9D%E0%A4%BE%E0%A4%B2%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0_%E0%A4%97%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2_%E0%A4%B2%E0%A5%8B%E0%A4%95_%E0%A4%9C%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%86%E0%A4%AA%E0%A4%A4%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%9A%E0%A5%80_%E0%A4%9D%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%9A%E0%A5%80_%E0%A4%AA%E0%A4%BE%E0%A4%A8%E0%A5%87_%E0%A4%B8%E0%A5%8B%E0%A4%A8%E0%A5%87_%E0%A4%AE%E0%A5%8D%E0%A4%B9%E0%A4%A3%E0%A5%82%E0%A4%A8_%E0%A4%B2%E0%A5%81%E0%A4%9F%E0%A4%A4%E0%A4%BE%E0%A4%A4_%E0%A4%A4%E0%A5%87_%E0%A4%86%E0%A4%AA%E0%A4%A4%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%9A%E0%A5%87_%E0%A4%9D%E0%A4%BE%E0%A4%A1._v6pxkv.jpg",
    caption:
      "दसरा महोत्सवाचे वेळी देव लग्न झाल्यावर गावातील लोक ज्या आपत्याची झाडाची पाने सोने म्हणून लुटतात ते आपत्याचे झाड",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301737/Website%20images/Kandalgaon/%E0%A4%A6%E0%A4%BE%E0%A4%9C%E0%A5%80_%E0%A4%A7%E0%A4%BE%E0%A4%95%E0%A5%8B%E0%A4%9C%E0%A5%80_%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87_%E0%A4%AF%E0%A4%BE%E0%A4%82%E0%A4%9A%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%85%E0%A4%82%E0%A4%97%E0%A4%A3%E0%A4%BE%E0%A4%A4%E0%A5%80%E0%A4%B2_%E0%A4%A4%E0%A5%81%E0%A4%B3%E0%A4%B6%E0%A5%80_%E0%A4%B5%E0%A5%83%E0%A4%82%E0%A4%A6%E0%A4%BE%E0%A4%B5%E0%A4%A8_%E0%A4%AE%E0%A4%A7%E0%A4%B2%E0%A5%80_%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87%E0%A4%B5%E0%A4%A1%E0%A5%80_bdsqaa.jpg",
    caption: "दाजी धाकोजी राणे यांच्या अंगणातील तुळशी वृंदावन मधली राणेवाडी",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714889343/Website%20images/Kandalgaon/WhatsApp_Image_2024-05-05_at_11.37.23_6b738ede_g67vce.jpg",
    caption:
      "मारुतीची घाटी (मारुती मंदिर) ज्या मार्गावरून छत्रपती शिवाजी महाराज सिंधुदुर्ग किल्ल्यावरून श्री रामेश्वर मंदिरात येत असत",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301747/Website%20images/Kandalgaon/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5_%E0%A4%AC%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%B9%E0%A5%8D%E0%A4%AE%E0%A4%A3_%E0%A4%B8%E0%A5%8D%E0%A4%A5%E0%A4%B3_%E0%A4%96%E0%A4%BE%E0%A4%B2%E0%A4%9A%E0%A5%80_%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%A1%E0%A5%80_ts2npd.jpg",
    caption: "श्री देव ब्राह्मण स्थळ खालची राणेवाडी",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301751/Website%20images/Kandalgaon/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5_%E0%A4%AE%E0%A4%B9%E0%A4%BE%E0%A4%AA%E0%A5%81%E0%A4%B0%E0%A5%81%E0%A4%B7_%E0%A4%AE%E0%A4%A7%E0%A4%B2%E0%A5%80_%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87%E0%A4%B5%E0%A4%A1%E0%A5%80_au2vc0.jpg",
    caption: "श्री देव महापुरुष (मधली राणेवाडी)",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301757/Website%20images/Kandalgaon/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5_%E0%A4%B0%E0%A4%B5%E0%A4%B3%E0%A4%A8%E0%A4%BE%E0%A4%A5._p48ueb.jpg",
    caption: "श्री देव रवळनाथ",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301768/Website%20images/Kandalgaon/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5%E0%A5%80_%E0%A4%95%E0%A4%AE%E0%A4%B0%E0%A4%BE%E0%A4%A6%E0%A5%87%E0%A4%B5%E0%A5%80_%E0%A4%AE%E0%A4%82%E0%A4%A6%E0%A4%BF%E0%A4%B0._tagv2x.jpg",
    caption: "श्री देवी कमरादेवी मंदिर",
  },
  {
    file: "http://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301774/Website%20images/Kandalgaon/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80_%E0%A4%A6%E0%A5%87%E0%A4%B5%E0%A5%80_%E0%A4%AD%E0%A4%B5%E0%A4%BE%E0%A4%88._rsfojg.jpg",
    caption: "श्री देवी भवाई",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301779/Website%20images/Kandalgaon/%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%AF%E0%A4%82%E0%A4%AD%E0%A5%82_%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80%E0%A4%A6%E0%A5%87%E0%A4%B5_%E0%A4%B0%E0%A4%BE%E0%A4%AE%E0%A5%87%E0%A4%B6%E0%A5%8D%E0%A4%B5%E0%A4%B0%E0%A4%9A%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%AA%E0%A4%BF%E0%A4%82%E0%A4%A1%E0%A5%80%E0%A4%B5%E0%A4%B0_%E0%A4%98%E0%A5%81%E0%A4%AE%E0%A4%9F%E0%A5%80_%E0%A4%AC%E0%A4%BE%E0%A4%82%E0%A4%A7%E0%A4%B2%E0%A5%8D%E0%A4%AF%E0%A4%BE%E0%A4%B5%E0%A4%B0_%E0%A4%B6%E0%A4%BF%E0%A4%B5%E0%A4%B0%E0%A4%BE%E0%A4%AF%E0%A4%BE%E0%A4%82%E0%A4%A8%E0%A5%80_%E0%A4%9C%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%B5%E0%A4%A1%E0%A4%BE%E0%A4%9A%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%9D%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%9A%E0%A5%87_%E0%A4%B0%E0%A5%8B%E0%A4%AA%E0%A4%9F%E0%A5%87_%E0%A4%B2%E0%A4%BE%E0%A4%B5%E0%A4%B2%E0%A5%87_%E0%A4%A4%E0%A5%87_%E0%A5%A9%E0%A5%AB%E0%A5%A6_%E0%A4%B5%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BE_%E0%A4%AA%E0%A5%82%E0%A4%B0%E0%A5%8D%E0%A4%B5%E0%A5%80_%E0%A4%B5%E0%A4%A1%E0%A4%BE%E0%A4%9A%E0%A5%87_%E0%A4%9D%E0%A4%BE%E0%A4%A1_%E0%A4%B5_%E0%A4%B6%E0%A4%BF%E0%A4%B5%E0%A4%B0%E0%A4%BE%E0%A4%AF%E0%A4%BE%E0%A4%82%E0%A4%9A%E0%A5%80_%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A4%E0%A4%BF%E0%A4%AE%E0%A4%BE_r42i7s.jpg",
    caption:
      "स्वयंभू श्रीदेव रामेश्वरच्या पिंडीवर घुमटी बांधल्यावर शिवरायांनी ज्या वडाच्या झाडाचे रोपटे लावले ते ३५० वर्षा पूर्वी वडाचे झाड व शिवरायांची प्रतिमा",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714209380/Website%20images/Kandalgaon/12_cpvctr.jpg",
    // caption: "वाकलेले आंब्याचे झाड",
  },

  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/q_auto:eco/v1714301714/Website%20images/Kandalgaon/20240421_140507_odnxx8.jpg",
    caption: "कांदळगाव - हडी सीमा देवीचे ठिकाण",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301710/Website%20images/Kandalgaon/20240421_140150_jnmypd.jpg",
    caption: "खणीवरची तुळस (मधली राणेवडी)",
  },
];

export const headerImages = [
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/q_auto:good/v1714381104/Website%20images/Home%20Header/header_fyv2un.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/q_auto:good/v1714381097/Website%20images/Home%20Header/header3_cuatrb.jpg",
  },
];

export const ShivpreranaImages = [
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714209107/Website%20images/Shivprerana/Shivprerana_TOP_guq8j3.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208726/Website%20images/Shivprerana/20240421_141425_r2vbbe.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208776/Website%20images/Shivprerana/20240421_142054_ugb6nn.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208774/Website%20images/Shivprerana/20240421_141936_je6qgi.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208770/Website%20images/Shivprerana/20240421_141738_hhfun4.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208768/Website%20images/Shivprerana/20240421_141624_ymppbh.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208766/Website%20images/Shivprerana/20240421_141523_uc7f1n.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208724/Website%20images/Shivprerana/20240421_141241_mg5s3f.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714208720/Website%20images/Shivprerana/20240421_140946_grjhqe.jpg",
  },
];

export const TraiVarhsikImages = [
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301188/Website%20images/festival/%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BF%E0%A4%95/%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BF%E0%A4%95_ppxbvh.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301187/Website%20images/festival/%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BF%E0%A4%95/%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BF%E0%A4%95_hvwo7p.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301076/Website%20images/festival/%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BF%E0%A4%95/Event_oq6rbj.jpg",
  },
  {
    file: "https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/v1714301074/Website%20images/festival/%E0%A4%A4%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%B7%E0%A4%BF%E0%A4%95/Event_nmrfx0.jpg",
  },
];
