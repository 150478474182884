import React from "react";
import SectionReveal from "../../components/SectionReveal";
import RightToLeft from "../../components/Transitions/RightToLeftText";
import "../../assets/Ranes.css";

const HistoryRanes = () => {
  return (
    <div className="">
      <header class="site-header section-padding ">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 ">
              <h1>
                <span class="navy-blue">साक्ष इतिहासाची - </span>
                <span className="text-primary">वैभवशाली परंपरेची</span>
                <br />
                <h2 class="navy-blue">
                  {" "}
                  - &#8216;&#8216;आम्ही कांदळगांवचे राणे&#8217;&#8217;
                </h2>
              </h1>
            </div>
          </div>
        </div>

        <img
          src="https://res.cloudinary.com/dpjkhc3rk/image/upload/q_auto:eco/f_auto/v1714210176/Website%20images/History%20of%20kandalgaon/%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87_%E0%A4%AE%E0%A5%81%E0%A4%B3%E0%A4%AA%E0%A5%81%E0%A4%B0%E0%A5%81%E0%A4%B7_%E0%A4%A6%E0%A4%BE%E0%A4%A6%E0%A4%9C%E0%A5%80_%E0%A4%B0%E0%A4%BE%E0%A4%A3%E0%A5%87_%E0%A4%86%E0%A4%AA%E0%A4%B2%E0%A5%8D%E0%A4%AF%E0%A4%BE_%E0%A4%B8%E0%A5%88%E0%A4%A8%E0%A4%BF%E0%A4%95%E0%A4%BE%E0%A4%82%E0%A4%B8%E0%A4%B9_%E0%A4%AF%E0%A4%BE%E0%A4%9A%E0%A4%A0%E0%A4%BF%E0%A4%95%E0%A4%BE%E0%A4%A3%E0%A5%80_%E0%A4%AA%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%B5%E0%A4%BE%E0%A4%A4%E0%A5%82%E0%A4%A8_%E0%A4%89%E0%A4%A4%E0%A4%B0%E0%A4%B2%E0%A5%87_nfwpno.jpg" // replace with your image source
          class="header-image img-fluid"
          alt=""
        />
      </header>

      <SectionReveal cssClasses="container section-padding">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <ul class="timeline">
              <li>
                <h4>मूळ स्थान</h4>
                <RightToLeft>
                  <p>राजस्थान-उदयपूर येथील राजघराणे</p>
                </RightToLeft>
              </li>
            </ul>
          </div>
        </div>
      </SectionReveal>

      <section class=" ">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <p className=" ">
                <img
                  className="d-block w-100 "
                  src="https://res.cloudinary.com/dpjkhc3rk/image/upload/q_auto:eco/f_auto/v1714210174/Website%20images/History%20of%20kandalgaon/%E0%A4%95%E0%A4%BE%E0%A4%82%E0%A4%A6%E0%A4%B3%E0%A4%97%E0%A4%BE%E0%A4%B5_%E0%A4%B8%E0%A5%80%E0%A4%AE%E0%A5%87%E0%A4%B5%E0%A4%B0%E0%A5%80%E0%A4%B2_%E0%A4%B6%E0%A5%87%E0%A4%B2%E0%A4%9F%E0%A5%80_%E0%A4%AF%E0%A5%87%E0%A4%A5%E0%A5%80%E0%A4%B2_%E0%A4%96%E0%A4%BE%E0%A4%A1%E0%A5%80%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A6%E0%A5%87%E0%A4%B6_gn6atj.jpg"
                  alt="First slide"
                  style={{ height: "500px", objectFit: "cover" }}
                />
              </p>
            </div>
            <div class="col-md-7 mt-4">
              <p>
                कांदळगांवांतील "राणे मूळपुरूषाचे" ॠण अमान्य करील अशी एकही
                व्यक्ति त्या गावांत आढळणार नाही. कारण, कांदळगांवातील राणे
                मूळपुरूषाने ३५० वर्षापूर्वी त्या गांवात केलेले सत्कार्य ही
                वस्तूस्थिती, नव्हे ज्वलंत इतिहास आहे. साधारणतः १४ पिढ्यांपूर्वी
                अर्थात ३५० वर्षापूर्वी दादजी (पटेल) राणे हा राजपुरूष आपल्या १५०
                सैनिकासह जलमार्गे शेलटी या ठिकाणी उतरला. अल्पावधीतच, कांदळगांवचे
                मूळचे रहिवाशी (पूर्व सत्ताधारी) यांच्या पूर्व संमतीने व गांवातील
                सर्व रहिवाश्यांच्या सहकार्याने तत्कालीन व्यवस्थेनुसार कार्यरत
                असलेल्या तेथील मुख्य सरदाराच्या मनमानी कारभाराच्या जाचातून
                सर्वांची यशस्वीरित्या मुक्तता करून राजसत्ता संपादन केली.
                राजसत्ताधारी, पूर्वसत्ताधारी व बारा बलुतेदार (सेवक) यांच्या
                संपूर्ण सहकार्याने गांवरहाटी (गांवची व्यवस्था) व देव रहाटी
                (देवस्थानाचा कारभार) या बाबत नीतीनियम करून त्याची न्याय्य
                पध्दतीने व सर्वांना सम्मानाची वागणूक देऊन यशस्वीरीत्या
                अंमलबजावणी केली व एक न्यायी राजसत्ताधारी म्हणून समाज्यामध्ये
                विशिष्ट स्थान प्राप्त केले. हा एक प्रेरणादायक इतिहास आहे.
              </p>

              <p>
                काळानुरूप व भारताच्या स्वातंत्र्य प्राप्तीनंतर अशा व्यवस्थेमध्ये
                थोडेफार बदल झाले असले तरी ज्या ज्या ठिकाणी शहान्नवकुळी मराठयांचे
                राज्य होते त्या त्या प्रांतात / गांवात, खास करून, कोकणांत गांव
                रहाटी, देवरहाटी या रूढी, परंपरा व चालीरिती आजही अस्तित्वात आहेत
                हे कोणीही नाकारूं शकणार नाही, कारण ते सत्य आहे.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className=" container">
        <h6 className="navy-blue ">
          कांदळगावच्या राणेंना दांडेकर राणे म्हटलं जातं. त्यामागे सांगितली
          जाणारी आख्यायिका..
        </h6>
        <p>
          साधारण साडेतीनशे वर्षांपूर्वी १४ पिढ्यांआधी कांदळगावच्या राणेंचे
          मुळपुरुष दादजी राणे (राणा) यांचे राजस्थानहून शेलटीच्या खाडीजवळ आगमन
          झाले आणि सुरुवातीच्या काळात त्याचा ताफा तेथेचे वस्ती करून राहू लागला.
          त्यावेळी कांदळगाववर परबांचे राज्य होते. तत्कालीन परब राजघराण्यातील
          राजस्त्री निपुत्रिक होती. त्याचवेळी तेथील सुभ्यावर यवनी मनसबदार हे
          राज्य हडपण्याच्या प्रयत्नांत होता. आणि त्यासाठी तो या राजस्त्रीस शक्य
          तो मानसिक त्रास देण्याचा प्रयत्न करत होता.
        </p>
        <p>
          याचदरम्यान कांदळगाव सीमेजवळ शेलटी येथे राजस्थान येथील क्षत्रिय
          घराण्यातून कोणी सरदार वास्तव्यास आल्याची माहिती या संस्थानाच्या
          सचिवांकडून परबांच्या राजस्त्रीस देण्यात आली. यवनी मनसबदाराच्या या
          त्रासाला कंटाळलेल्या राजस्त्रीने बहिणीच्या नात्याने मदतीसाठी राणे
          मुळपुरुषाला आवाहन केले आणि या त्रासातून मुक्तता केल्यास अर्धे राज्य
          देण्याचे मान्य केले. यावेळी केवळ १५० सैनिकांसह शेलटीस वास्तव्यास
          असलेल्या राणे मुळपुरुषाने मदतीचा हात पुढे केला.
        </p>
        <p>
          आपल्याकडे असलेल्या कमी मनुष्यबळाचा अंदाज घेत, सैनिकांची हानी होऊ नये
          यासाठी दादजी राणे यांनी या मनसबदाराला द्वंदयुद्धाचे आव्हान दिले. आणि
          गुर्मीत असलेल्या मनसबदाराने ते स्विकारले. या लढाईत केवळ एका दांड्याने
          राणे मुळपुरुषाने या मनसबदाराचा खात्मा केला आणि त्याच्या सैन्याला
          पिटाळून लावले. या धाडसाचा गौरव करताना परब संस्थानाने दादजी राणे यांचा
          दांडेकर राणे म्हणून उल्लेख केला आणि तोच पुढे प्रचलित झाला. आणि
          ठरावानुसार अर्धे राज्य राणेंना देण्यात आले. तदनंतर, कांदळगावांत
          राजसत्ता (मर्दुमकीने मिळवलेली) म्हणून राणेंची, तर पुर्वसत्ता म्हणून
          परबांची सत्ता रुढ झाली.
        </p>
        <p>
          या दंतकथेला आधार म्हणजे आजही कांदळगावच्या श्री देव रामेश्वर देवस्थानात
          कुठल्याही धार्मिक विधीप्रसंगी, शुभप्रसंगी राणे आणि परब यांचे मानकरी
          असल्याशिवाय वारेसुत्र येत नाहीत. (कोणीही एका सत्तेचे मानकरी नसल्यास
          वारे येत नाही) तसेच श्री देव रामेश्वर देवस्थानाच्या कमिटीत देखील राणे
          आणि परबांचे समान सदस्य आहेत.
        </p>
      </section>

      <section class="testimonial section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 mx-auto col-11">
              <div class="slick-testimonial">
                <div class="slick-testimonial-caption">
                  <RightToLeft>
                    <p class="lead">
                      कांद‌ळगावालील पूर्व सस्ताधारी व्यक्तिंची गुलामगिरीतून
                      मुक्तता व त्याना त्यांची सत्ता बहाल.
                    </p>
                  </RightToLeft>
                </div>

                <div class="slick-testimonial-caption">
                  <RightToLeft>
                    <p class="lead">
                      राज सत्ता, पूर्व सत्ता व सेवेकरी म्हणून कार्य करणाऱ्या
                      बारा बलुतेदारांची जबाबदारी व हक याबाबत निर्देश देऊन स्पष्ट
                      नितिनियक गांव रहाटी व देव रहाटीची व्यवस्था.
                    </p>
                  </RightToLeft>
                </div>

                <div class="slick-testimonial-caption">
                  <RightToLeft>
                    <p class="lead">
                      गांवातील कुठलीही एक जात जमात, समाज, गट दुसन्यावर अन्याय
                      करणार नाही याबाबत दक्षता म्हणून गांव रहाटी व देव
                      रहाटीबाबतच्या जीतीचे सर्व अधिकार स्वत:कडे ठेऊन त्या
                      राजधर्माचे काटेकोर पालन. अशी ही सुव्यवस्था गेल्या चौदा
                      पिढ्यानंतर ढासळत असून गावातील आजची परिस्थिती अराजकतेच्या
                      उंबरठ्यावर येऊन ठेपली आहे याबाबत समाज्यातील प्रत्येक घटक
                      स्वतःची जबाबदारी झटकून दुसऱ्याला दुषणे देण्यान धन्यता मानत
                      आहे.
                    </p>
                  </RightToLeft>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-padding">
        ५) उठा-जागे व्हा विचार करा : या परिस्थितीला जबाबदार कोणी १४ पिट्यापूर्वी
        गावान आथित्वात असलेले अराजक नष्ट करून न्याय व्यवस्थेची स्थापना करणारा
        राणे मूळ राजपुरुष ? आम्ही पूर्वसत्ताधारी पाहिजे म्हणून आग्रह म्हणून
        गोवान आमचाच धरणार पूर्वसत्ताधारी ? मेमेल चालला गांवालील राजसत्ताधान्यानी
        गोवरहारी व देवखारी बाबतू घालून दिलेल्या निय‌माचे उल्लंघून मम्हटलेन
        असताना त्याकडे दुलन करणारे कांदळगांववासिय ? सद्याची राजकीय आर्थिक,
        सामाजिक व राजकीय परिस्थिती की, या सर्वांचे नियंत्रण करणारी सरकारी येमजा
        ! याबाबत कोणी कोही म्हणा परेलू आहे की, आमच्या संखेचे जगातील प्रत्येक
        व्यक्ति निसर्ग व समान व्यवस्थेमधील विनियम अलिखित नियमांचे भान ठेऊन
        त्याबाबतीतील आपले दायित्व मान्य करत नाही तो पर्यंत हे असेच चालत राहणार.
      </section>

      <div className="section-padding">
        <div class="container mt-5 ">
          <h2 class="text-center">अशी आहे आमची संस्था-</h2>
          <ul class="list-group mt-3">
            <p className="list-group-item block-orange">
              • स्वयंभू को देव रामेश्वराकडून प्रेरणा लाभलेली.
            </p>
            <p class="list-group-item block-orange">
              • स्वःतःच्या कमाइतील कांही ठेऊन अंश सामाजिक बांधिलकीची जा जाण वभान
              वैद्यकीय क्रीडा-कला व सांस्कृतीक उन्नतीसाठी खर्च करणारी.
            </p>
            <p class="list-group-item block-orange">
              • कार्यरत कोणबाट राजाश्रय, सरकारी अभयाशिवाय मालीन असलेली.
            </p>
            <p class="list-group-item block-orange">
              • कोणत्या राजकीय किंवा गए लटांका किंवा से गालटोच्या किंवा राजकीय
              पुराऱ्याच्या उस्तक्षेपाला वाव नसलेली.
            </p>
            <p class="list-group-item block-orange">
              • श्रीमत-गरिब किंवा जतीपातीमध्ये प्रेद‌भाव न करणारी.
            </p>
            <p class="list-group-item block-orange  ">
              • कोपल्यारि पदाची, किंवा मोठेपणाची किंवा प्रसिध्दीच्या हब्बासाला
              बळी न पड‌ला केवळ सामाजिक कूण म्हणून निरपेक्ष बुध्दीन संस्थेच्या
              प्रगतीला समेत शक्य असेल तसा हातभार लावणाऱ्या सभासद कार्यक
              स्तिचिंतकांचे सबय्य लाभलेली.
            </p>
          </ul>
        </div>
      </div>

      <section className="section-padding">
        होय! आम्हाला मान्य आहे की, आमचीकी प्रेरया सद्या आपल्या जन्मगांवातील
        राणेवाड्यातील रहिवाश्यांच्या सर्वांगीण उन्नतीपुरली मर्यादित आहे कारण
        आमच्या वर उबेखिलेल्या ध्येय धारपांची जोपासना करायची झाल्यास आमचा ठाम
        विश्वास यस व्याफी वाढविण्यावर मर्याण ल्या येणारच परंतु ओहे की, सर्वागिण
        विकास देशाचा सव असे 1 प्रथन गोवाङ्गजांतून झाल्यास व उन्नती सह सहज शक्य
        आहे. चापल आमच झोचरण सहाय्य व मार्गदर्शनयो व मान्य असलेल्या व विचार
        आवडणाऱ्या प्रत्येका आम्ही सविनय स्विकार करूं.
      </section> */}
    </div>
  );
};

export default HistoryRanes;
