import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const fetchUpcomingEvents = async () => {
  try {
    // Create a query to get only the documents where the "upcoming" field is true
    const eventsRef = collection(db, "events");
    const q = query(eventsRef, where("upcoming", "==", true));

    // Execute the query
    const querySnapshot = await getDocs(q);

    const eventsData = [];
    querySnapshot.forEach((doc) => {
      eventsData.push({ id: doc.id, ...doc.data() });
    });

    // console.log(eventsData);

    return eventsData;
  } catch (error) {
    console.error("Error fetching upcoming events:", error);
    throw error; // Rethrow the error to handle it at the calling site
  }
};
