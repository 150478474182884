import React, { useState, useEffect, useRef } from "react";
import { fetchEventDetails } from "../firebaseUtils/eventsFetch";
import EventsCard from "../components/EventsCard";
import { fetchUpcomingEvents } from "../firebaseUtils/fetchUpcomingEvents";

const Events = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [events, setEvents] = useState(null);
  const [pageStarts, setPageStarts] = useState([null]); // Stack of first documents of each page
  const pageSize = 6; // Adjust as needed

  const motionRef = useRef();

  const getUpcomingEvents = async () => {
    const resp = await fetchUpcomingEvents();
    console.log(resp);
    setUpcomingEvents(resp);
  };

  const fetchNextPage = async () => {
    try {
      const lastVisible = pageStarts[pageStarts.length - 1];
      const {
        eventsData,
        firstVisible,
        lastVisible: newLastVisible,
      } = await fetchEventDetails(pageSize, lastVisible);
      setEvents(eventsData);
      setPageStarts([...pageStarts, firstVisible]);
      motionRef.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error("Error fetching next page:", error);
    }
  };

  const fetchPreviousPage = async () => {
    try {
      if (pageStarts.length < 2) {
        // If there's only one item in pageStarts, it means there are no more previous pages
        console.log("Already on the first page");
        return;
      }
      const newPageStarts = [...pageStarts];
      newPageStarts.pop(); // Remove the last item
      setPageStarts(newPageStarts);
      const lastVisible = newPageStarts[newPageStarts.length - 1];
      const { eventsData } = await fetchEventDetails(pageSize, lastVisible);
      setEvents(eventsData);

      motionRef.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error("Error fetching previous page:", error);
    }
  };

  useEffect(() => {
    // Fetch initial page when component mounts

    getUpcomingEvents();
    fetchNextPage();
  }, []);

  // useEffect(() => {

  // }, [fetchNextPage, fetchPreviousPage]);

  return (
    <div>
      <div class="site-header">
        <div className="container py-5">
          <h5>चालू उत्सव</h5>

          {!upcomingEvents.length ? (
            <div className="bg-no-events text-center rounded-4">
              <p className="p-2 ">कोणतेही आगामी कार्यक्रम नाहीत</p>
            </div>
          ) : (
            <div class="row ">
              {upcomingEvents.map((upcomingEvent) => (
                <EventsCard
                  key={upcomingEvent.id}
                  id={upcomingEvent.id}
                  event={upcomingEvent}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {/* <div ref={motionRef}></div> */}

      <div className="section-padding">
        <div class="container ">
          <h5 class="card-title">कार्यक्रम</h5>
          <div class="row">
            {events ? (
              events.map((event) => <EventsCard key={event.id} event={event} />)
            ) : (
              <div
                class="container d-flex align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border mx-auto" role="status"></div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="container">
          <button
            className="btn custom-btn m-2"
            onClick={fetchPreviousPage}
            disabled={pageStarts.length < 2}
          >
            Previous Page
          </button>
          <button className="btn custom-btn" onClick={fetchNextPage}>
            Next Page
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Events;
