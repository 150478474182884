import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleEvent } from "../firebaseUtils/singleEventFetch";
import { useNavigate } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Link } from "react-router-dom";

const Event = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null); // for gallery images
  const [eventMetaData, setEventMetaData] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [initialLoadCount, setInitialLoadCount] = useState(5); // Initial number of photos to load
  const [currentIndex, setCurrentIndex] = useState(initialLoadCount); // Current index of photos being displayed

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const loadMorePhotos = async () => {
    try {
      // const imagesWithDimensions = await Promise.all(
      //   eventMetaData.images
      //     .slice(currentIndex, currentIndex + 5)
      //     .map(async (url) => {
      //       const img = new Image();
      //       img.src = url.replace("http://", "https://");
      //       try {
      //         await img.decode(); // Wait for image to load
      //         return {
      //           src: url,
      //           thumbnail: url,
      //           width: img.naturalWidth,
      //           height: img.naturalHeight,
      //         };
      //       } catch (err) {
      //         console.error(`Error decoding image: ${url}`, err);
      //         return null; // Skip the problematic image
      //       }
      //     })
      //   // .filter(Boolean) // Filter out null values (skipped images)
      // );

      let filteredArray = eventMetaData.images
        .slice(currentIndex, currentIndex + 10)
        .map((image) => {
          return {
            src: image.url,
            width: image.width,
            height: image.height,
            thumbnail: image.url,
          };
        });
      console.log(filteredArray);
      setEvent([...event, ...filteredArray]);
      setCurrentIndex(currentIndex + 10);
    } catch (error) {
      console.error("Error loading more photos:", error);
    }
  };

  // const loadMorePhotos = async () => {
  //   const imagesWithDimensions = await Promise.all(
  //     eventMetaData.images
  //       .slice(currentIndex, currentIndex + 10)
  //       .map(async (url) => {
  //         const img = new Image();
  //         img.src = url;
  //         await img.decode(); // Wait for image to load
  //         return {
  //           src: url,
  //           thumbnail: url,
  //           width: img.naturalWidth,
  //           height: img.naturalHeight,
  //         };
  //       })
  //   );

  //   console.log(imagesWithDimensions);

  //   // setEvent([...event, imagesWithDimensions]);

  //   // // setEvent(updatedPhotos);
  //   // setCurrentIndex(currentIndex + 10);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSingleEvent(eventId);

        if (!data) {
          navigate("/not-found");
        }
        setEventMetaData(data);
        console.log(data);
        // const imagesWithDimensions = await Promise.all(
        //   data.images.slice(0, initialLoadCount).map(async (url) => {
        //     // const uploadIndex = url.indexOf("upload/");

        //     // // Insert 'q_auto:low/' after 'upload/'
        //     // const updatedUrl = `${url.slice(
        //     //   0,
        //     //   uploadIndex + 7
        //     // )}q_auto:low/${url.slice(uploadIndex + 7)}`;

        //     const img = new Image();
        //     img.src = url;

        //     await img.decode(); // Wait for image to load
        //     return {
        //       src: url,
        //       thumbnail: url,
        //       width: img.naturalWidth,
        //       height: img.naturalHeight,
        //     };
        //   })
        // );

        const imagesWithDimensions = data.images
          .slice(0, initialLoadCount)
          .map((image) => {
            return {
              src: image.url,
              width: image.width,
              height: image.height,
              thumbnail: image.url,
            };
          });

        console.log(imagesWithDimensions);

        setEvent(imagesWithDimensions);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, [eventId, initialLoadCount]);

  return (
    <div className="section-padding mt-4 container">
      <div className="container mt-2">
        <Link to="/events" className=" mb-4">
          {" "}
          ← Back to events
        </Link>

        {!event ? (
          <div
            className="container d-flex align-items-center"
            style={{ height: "50vh" }}
          >
            <div className="spinner-border mx-auto" role="status"></div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center my-4">{eventMetaData.title}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex align-items-center flex-column">
                <img
                  src={eventMetaData.images[1].url}
                  className="img-fluid w-50 mb-4"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card mb-4">
                  <div className="card-body  mx-auto">
                    <h5 className="card-title">DETAILS</h5>
                    <p className="card-text">Date: {eventMetaData.dateText}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-2 light-blue">
              <h5 className="card-title">Photos</h5>
              {event && (
                <Gallery
                  photos={event}
                  onClick={openLightbox}
                  targetRowHeight={350}
                  margin={4}
                />
              )}

              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={event.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>

            <div className="text-center mt-3">
              {currentIndex < eventMetaData.images.length && (
                <button className="btn btn-primary" onClick={loadMorePhotos}>
                  Load More
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Event;

// import React, { useEffect, useState, useCallback } from "react";
// import { useParams } from "react-router-dom";
// import { fetchSingleEvent } from "../firebaseUtils/singleEventFetch";
// import { useNavigate } from "react-router-dom";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";

// const Event = () => {
//   const { eventId } = useParams();
//   const navigate = useNavigate();
//   const [event, setEvent] = useState(null);
//   const [eventMetaData, setEventMetaData] = useState(null);
//   const [currentImage, setCurrentImage] = useState(0);
//   const [viewerIsOpen, setViewerIsOpen] = useState(false);

//   const openLightbox = useCallback((event, { photo, index }) => {
//     setCurrentImage(index);
//     setViewerIsOpen(true);
//   }, []);

//   const closeLightbox = () => {
//     setCurrentImage(0);
//     setViewerIsOpen(false);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await fetchSingleEvent(eventId);

//         if (!data) {
//           navigate("/not-found");
//         }
//         setEventMetaData(data);

//         const imagesWithDimensions = await Promise.all(
//           data.images.map(async (url) => {
//             const img = new Image();
//             img.src = url;
//             await img.decode(); // Wait for image to load
//             return {
//               src: url,
//               thumbnail: url,
//               width: img.naturalWidth,
//               height: img.naturalHeight,
//             };
//           })
//         );
//         setEvent(imagesWithDimensions);
//       } catch (error) {
//         console.error("Error fetching user details:", error);
//       }
//     };

//     fetchData(); // Call fetchData function when component mounts
//   }, [eventId]);
//   return (
//     <div className="section-padding mt-4 container">
//       <div className="container mt-2">
//         <a href="/events" className=" mb-4">
//           {" "}
//           ← Back to events
//         </a>

//         {!event ? (
//           <div
//             class="container d-flex align-items-center"
//             style={{ height: "50vh" }}
//           >
//             <div className="spinner-border mx-auto" role="status"></div>
//           </div>
//         ) : (
//           <>
//             <div className="row">
//               <div className="col-md-12">
//                 <h2 className="text-center my-4">{eventMetaData.title}</h2>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col d-flex align-items-center flex-column">
//                 <img
//                   src={eventMetaData.images[1]}
//                   className="img-fluid w-50 mb-4"
//                 />
//                 {/* {event?.upcoming ? (
//                   <p className="text-center mb-4"></p>
//                 ) : (
//                   <p className="text-center mb-4">This event has passed.</p>
//                 )} */}
//               </div>
//             </div>
//             <div className="row">
//               <div className="col">
//                 <div className="card mb-4">
//                   <div className="card-body  mx-auto">
//                     <h5 className="card-title">DETAILS</h5>
//                     <p className="card-text">Date: {eventMetaData.dateText}</p>
//                     {/* <p className="card-text">Time: 4:00 pm - 6:30 pm</p> */}
//                   </div>
//                 </div>
//               </div>
//               {/* <div className="col-md-6">
//                 <div className="card mb-4">
//                   <div className="card-body">
//                     <h5 className="card-title">ORGANIZER</h5>
//                     <p className="card-text">Vishwa Gujarati Samaj</p>
//                     <p className="card-text">Phone: 07926488342</p>
//                     <p className="card-text">
//                       Email: contact@vishwagujaratisa maj.net
//                     </p>
//                     <a href="#" className="card-link">
//                       View Organizer Website
//                     </a>
//                   </div>
//                 </div>
//               </div> */}
//             </div>
//             {/* <div className="row">
//               <div className="col-md-12">
//                 <img
//                   src="image2.jpg"
//                   className="img-fluid mb-4"
//                   alt="Event Image 2"
//                 />
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="card">
//                   <div className="card-body">
//                     <h5 className="card-title">VENUE</h5>
//                     <p className="card-text">
//                       ગુજરાત વિદ્યાલય ટ્રસ્ટ
//                       <br />
//                       (હિરાબાગ બેજરગંજી સભાગૃહ,
//                       <br />
//                       પ્રેમાબાઈની ચાળમાં, વિદ્યાલય માર્ગ,
//                       <br />
//                       ઉસ્માનપુરા, અમદાવાદ, ગુજરાત 380013
//                       <br />
//                       India + Google Map
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div> */}

//             <div className="card p-2 light-blue">
//               <h5 className="card-title">Photos</h5>
//               {event && (
//                 <Gallery
//                   photos={event}
//                   onClick={openLightbox}
//                   // renderImage={MyPhoto}
//                   targetRowHeight={350}
//                   margin={4}
//                   // direction={"column"}
//                 />
//               )}

//               <ModalGateway>
//                 {viewerIsOpen ? (
//                   <Modal onClose={closeLightbox}>
//                     <Carousel
//                       currentIndex={currentImage}
//                       views={event.map((x) => ({
//                         ...x,
//                         srcset: x.srcSet,
//                         caption: x.title,
//                       }))}
//                     />
//                   </Modal>
//                 ) : null}
//               </ModalGateway>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Event;
