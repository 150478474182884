import React from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const { currentUser } = useContext(AuthContext);

  console.log(currentUser);

  const navigate = useNavigate();

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        alert("Logout was successful");
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        // An error occurred
        console.error("Logout error:", error);
      });
  };
  return (
    <div className="section-padding">
      <div className="container">
        <h2>Account Information</h2>
        <div>
          <p>
            <strong>Name:</strong> {currentUser.displayName}
          </p>
          <p>
            <strong>Username:</strong> {currentUser.email?.split("@")[0]}
          </p>
        </div>
        <button className="btn custom-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Account;
