import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to submit form data to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "contact"), formData);

      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });

      alert("Data has been submitted successfully !");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <div>
      <header class="site-header section-padding-img site-header-image">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-12 header-info">
              <h1>
                <span class="d-block text-primary">आम्हाला तुमच्याकडून</span>
                <span class="d-block navy-blue">ऐकायला आवडेल</span>
              </h1>
            </div>
          </div>
        </div>

        <LazyLoadImage
          class="header-image img-fluid"
          src="https://res.cloudinary.com/dpjkhc3rk/image/upload/f_auto/p_auto/v1714214093/Website%20images/Contact/contach_page_xgzggm.jpg"
        />
      </header>
      <section className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <form
                className="contact-form me-lg-5 pe-lg-3"
                onSubmit={handleSubmit}
              >
                <div className="form-floating">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Full name"
                    required
                  />
                  <label htmlFor="name">Full name / पूर्ण नाव</label>
                </div>

                <div className="form-floating my-4">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    pattern="[^ @]*@[^ @]*"
                    className="form-control"
                    placeholder="Email address"
                    required
                  />
                  <label htmlFor="email">Email address / ईमेल पत्ता</label>
                </div>

                <div className="form-floating my-4">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Subject"
                    required
                  />
                  <label htmlFor="subject">Subject / विषय</label>
                </div>

                <div className="form-floating mb-4">
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Leave a comment here"
                    required
                    style={{ height: "160px" }}
                  ></textarea>
                  <label htmlFor="message">
                    Any issues or recommendations / कोणतीही समस्या किंवा सल्ला
                  </label>
                </div>

                <div className="col-lg-5 col-6">
                  <button type="submit" className="form-control">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
