import React, { useState, useEffect } from "react";
import { createContext } from "react";
import { realtimeDB } from "../firebaseConfig";
import { ref, set, get, onValue } from "firebase/database";

export const VisitCounterContext = createContext();

export const VisitCounterProvider = ({ children }) => {
  const [visits, setVisits] = useState(null);

  useEffect(() => {
    const lastVisitTime = localStorage.getItem("lastVisitTime");
    const currentTime = new Date().getTime();
    const timeSinceLastVisit = currentTime - lastVisitTime;

    if (!lastVisitTime || timeSinceLastVisit > 120000) {
      // If it's the first visit or more than 5 minutes have passed since the last visit
      localStorage.setItem("lastVisitTime", currentTime);
      const visitCounterRef = ref(realtimeDB, "visitCounter/counterValue");
      get(visitCounterRef).then((snapshot) => {
        if (snapshot.exists()) {
          const visitsOld = snapshot.val();
          set(ref(realtimeDB, "visitCounter/counterValue"), visitsOld + 1);
        }
      });
    }
  }, []);

  useEffect(() => {
    const visitCounterRef = ref(realtimeDB, "visitCounter/counterValue");

    const unsubscribe = onValue(visitCounterRef, (snapshot) => {
      const data = snapshot.val();
      setVisits(data);
    });

    return () => {
      // Unsubscribe from the listener when component unmounts
      unsubscribe();
    };
    // get(visitCounterRef).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     const visits = snapshot.val();
    //     setVisits(visits);
    //   }
    // });
  }, []);

  return (
    <VisitCounterContext.Provider value={{ visits }}>
      {children}
    </VisitCounterContext.Provider>
  );
};
