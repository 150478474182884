import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./context/PrivateRoute";
import { Navbar, Footer, ScrollToTop } from "./components/componentimports";
import {
  Home,
  Login,
  Signup,
  Event,
  Events,
  Account,
  Contact,
  PageNotFound,
  Kandalgaon,
  Shivprerana,
  Members,
  HistoryRanes,
  VillageFestival,
  PasswordReset,
} from "./pages/pageimports";
import { analytics } from "./firebaseConfig";
import { logEvent } from "firebase/analytics";

function App() {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    logEvent(analytics, "page_view");
  }, []);

  return (
    <div>
      <section class={isVisible ? "preloader" : "preloader hidden"}>
        <div class="spinner"></div>
      </section>

      <main>
        <ScrollToTop />
        <Navbar />

        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/kandalgaon/history-ranes" element={<HistoryRanes />} />
          <Route path="/kandalgaon/" element={<Kandalgaon />} />
          <Route
            path="/kandalgaon/village-festival"
            element={<VillageFestival />}
          />

          <Route path="/Shivprerana" exact element={<Shivprerana />} />

          <Route path="/login" exact element={<Login />} />
          <Route path="/signup" exact element={<Signup />} />
          <Route path="/event-info/:eventId" element={<Event />} />
          {/* <Route path="/event" exact element={<EventsList />} /> */}

          <Route path="/contact" exact element={<Contact />} />
          <Route path="/events" element={<Events />} />

          <Route element={<PrivateRoute />}>
            <Route path="/members" element={<Members />} />
            <Route path="/account-info" exact element={<Account />} />
            <Route path="/password-reset" element={<PasswordReset />} />
          </Route>
          <Route path="/not-found" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
